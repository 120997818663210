/* ***********************
      Responsive
************************** */

@media (min-width: 300px) and (max-width: 374px) {
  .checkout-page .items-list .image img {
    width: 228px !important;

    margin-left: -16px !important;
  }
  .items-list {
    width: 283px !important;
  }

  .headerCenter {
    margin-left: 0px;
  }
  .product-title-on-Img {
    font-size: 11px !important;

    bottom: -31px !important;
    left: 25px !important;
  }
  .scarves-page .shop-now {
    margin-right: 22px !important;
  }
  .row.main-cards .card-item {
    margin-left: 0px !important;
    margin-top: 29px;
  }
  button.add-to-cart-btn {
    padding: 5px 35px 5px 35px;
    font-size: 15px;
  }

  .cart-items .item {
    justify-content: flex-start;

    padding: 20px 111px 20px 19px;
    height: 120px;
    display: flex;
  }
  .cart-items .quantity {
    width: 144px;
  }
  .auth-page .center-form {
    padding: 0 23px;
    margin-top: 0px;
  }
  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
    margin-top: -36px !important;
  }
  .form-area.container {
    top: 75px;
    position: relative;
  }
  .stockists .bottom-right-logo {
    left: 0px !important;
    bottom: -33px;
  }
  .item {
    padding: 20px 47px;
    height: 120px;
    display: flex;
  }
 

  .scarves-details ul.menu.left,
  .scarves-details ul.menu.right {
    /* margin-top: -70px; */
  }
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: 10px;
    right: 0px;
    width: 5rem;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 394px;
    object-fit: cover;
  }
  .scarves-page .shop-now {
    margin-top: -15%;
  }
  .closebtn {
    display: block !important;
  }

  .bghjHX {
    margin: 0 0 0 0 !important;
  }
  .bottom-right-logo {
    position: absolute;
    bottom: 12px;
    right: 0px;
    width: 5rem;
  }

  h1.whypage-heading {
    font-size: 2.5rem;
  }
  #page-height {
    text-align: justify;
  }
  .plus-icon img {
    width: 20px;
  }
  .home-page .sub-heading {
    font-size: 1rem;
    top: 102%;
    left: 34%;
  }

  .footer-centerPart {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .footer-centerPart p {
    font-size: 0.9rem;
    margin-top: 14px;
  }

  .footer-centerPart img {
    width: 80px;
    height: 75px;
    margin-top: 30px;
  }
  img.logo {
    width: 90px;
    /* height: 76px; */
    margin-top: 14px;
    margin-left: 23px;
  }

  .why-page .leftSide-center-text {
    height: auto;
    overflow-y: auto;
    display: block;
    order: 2;
    overflow-x: hidden;
  }

  .top-border {
    border-bottom: solid #ff8d6b;
    border-top: none;
  }
  .why-page .col-md-6.right {
    height: 386px;
  }
  .item {
    height: auto;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .shopping-cart {
    height: 100%;
  }
  .shopping-cart .image img {
    width: 80px;
  }
  .scarves-page .shop-now {
    font-size: 18px;
  }

  div#myCarousel {
    margin-top: 77px;
  }

 

  .play-page .footer-centerPart {
    display: none;
  }
  .play-page .heading {
    /* text-align: center; */
    padding-left: 83px;
  }

  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 83px;
  }

  .image,
  .quantity,
  .description {
    width: 100%;
  }

  .attribute-main {
    width: 100%;
  }
  .sc-bZQynM.FvPZw {
    width: 100px;
  }

  .play-page .right-side {
    margin-top: 5%;
    padding-left: 83px;
  }

  .play-email input {
    width: 87% !important;
  }

  .checkbox-wrapper-37 .checkbox-svg {
    width: 30px;
    height: 30px;
  }

  input.input-felid.qty {
    width: 36px;
    height: 45px;
  }
  label.qty-text {
    font-size: 10px;
  }
  .checkbox-wrapper-37 .terms-label .label-text {
    padding: 0 15px;
    font-size: 10px;
  }
  input.input-felid.address {
    width: 35% !important;
  }

  /* authpages */
  .auth-left-col.col-md-6 {
    order: 2;
    display: none;
  }
  .auth-right-col.col-md-6 {
    height: 100vh;
  }
  .auth-page .header,
  .auth-page .footer {
    width: 100% !important;
  }
  .total-price {
    font-size: 15px;
    color: #e1523e;
    font-weight: 300;
    /* display: flex; */
    width: 109px;
    margin-top: 29px;
    margin-right: -88px;
  }
  .title {
    height: 60px;
    border-bottom: 1px solid #e1e8ee;
    /* padding: 20px 20px 20px 78px; */
    color: #5e6977;
    font-size: 15px;
    font-weight: 400;
  }
  h1.heading-Mainpage {
    font-size: 50px;
  }
  /* .greenLogo img {
    bottom: 168px !important;
    left: 33% !important;
    width: 125px;
  } */

  .closebtn {
    display: block;
  }
  label.form-check-label {
    font-size: 13px;
  }
  .form-group label:before {
    padding: 5px;
  }
  /* Checkout Page */

  .checkout-page .col {
    margin-right: 5px !important;
  }

  .login-fileds-checkout {
    padding-right: 7px !important;
  }

  .Billing-address-box {
    margin-right: 5px;
  }
}
@media (min-width: 375px) and (max-width: 767px) {
  .sub-total {
    width: 24em !important;
  }
  .headerCenter {
    margin-left: 0px;
  }
  .product-title-on-Img {
    font-size: 11px !important;

    bottom: -31px !important;
    left: 25px !important;
  }
  .scarves-page .shop-now {
    margin-right: 22px !important;
  }
  .row.main-cards .card-item {
    margin-left: 0px !important;
    margin-top: 29px;
  }
  button.add-to-cart-btn {
    padding: 5px 35px 5px 35px;
    font-size: 15px;
  }

  .cart-items .item {
    justify-content: flex-start;

    padding: 20px 111px 20px 19px;
    height: 120px;
    display: flex;
  }
  .cart-items .quantity {
    width: 144px;
  }
  .auth-page .center-form {
    padding: 0 23px;
    margin-top: 0px;
  }
  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
    margin-top: -36px !important;
  }
  .form-area.container {
    top: 75px;
    position: relative;
  }
  .stockists .bottom-right-logo {
    left: 0px !important;
    bottom: -33px;
  }
  .item {
    padding: 20px 47px;
    height: 120px;
    display: flex;
  }
   

  .scarves-details ul.menu.left,
  .scarves-details ul.menu.right {
    /* margin-top: -70px; */
  }
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: 10px;
    right: 0px;
    width: 5rem;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 394px;
    object-fit: cover;
  }
  .scarves-page .shop-now {
    margin-top: -15%;
  }
  .closebtn {
    display: block !important;
  }

  .bghjHX {
    margin: 0 0 0 0 !important;
  }
  .bottom-right-logo {
    position: absolute;
    bottom: 12px;
    right: 0px;
    width: 5rem;
  }

  h1.whypage-heading {
    font-size: 2.5rem;
  }
  #page-height {
    text-align: justify;
  }
  .plus-icon img {
    width: 20px;
  }
  .home-page .sub-heading {
    font-size: 1rem;
    top: 102%;
    left: 34%;
  }

  .footer-centerPart {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .footer-centerPart p {
    font-size: 0.9rem;
    margin-top: 14px;
  }
  .copyright-text p {
    font-size: 0.6rem;
  }
  .copyright-text {
    width: 166%;
    margin-left: -50px;
  }
  .footer-centerPart img {
    width: 80px;
    height: 75px;
    margin-top: 30px;
  }
  img.logo {
    width: 90px;
    /* height: 76px; */
    margin-top: 14px;
    margin-left: 23px;
  }

  .why-page .leftSide-center-text {
    height: auto;
    overflow-y: auto;
    display: block;
    order: 2;
    overflow-x: hidden;
  }

  .top-border {
    border-bottom: solid #ff8d6b;
    border-top: none;
  }
  .why-page .col-md-6.right {
    height: 386px;
  }
  .item {
    height: auto;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .shopping-cart {
    height: 67%;
  }
  .shopping-cart .image img {
    width: 80px;
  }
  .scarves-page .shop-now {
    font-size: 18px;
  }

  div#myCarousel {
    margin-top: 77px;
  }

  /* playpage */
 

  .play-page .footer-centerPart {
    display: none;
  }
  .play-page .heading {
    /* text-align: center; */
    padding-left: 83px;
  }

  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 83px;
  }

  .image,
  .quantity,
  .description {
    width: 100%;
  }

  .attribute-main {
    width: 100%;
  }
  .sc-bZQynM.FvPZw {
    width: 100px;
  }

  .play-page .right-side {
    margin-top: 5%;
    padding-left: 83px;
  }

  .play-email input {
    width: 87% !important;
  }

  .checkbox-wrapper-37 .checkbox-svg {
    width: 30px;
    height: 30px;
  }

  input.input-felid.qty {
    width: 36px;
    height: 45px;
  }
  label.qty-text {
    font-size: 10px;
  }
  .checkbox-wrapper-37 .terms-label .label-text {
    padding: 0 15px;
    font-size: 10px;
  }
  input.input-felid.address {
    width: 35% !important;
  }

  /* authpages */
  .auth-left-col.col-md-6 {
    order: 2;
    display: none;
  }
  .auth-right-col.col-md-6 {
    height: 100vh;
  }
  .auth-page .header,
  .auth-page .footer {
    width: 100% !important;
  }
  .total-price {
    font-size: 15px;
    color: #e1523e;
    font-weight: 300;
    /* display: flex; */
    width: 109px;
    margin-top: 29px;
    margin-right: -88px;
  }
  .title {
    height: 60px;
    border-bottom: 1px solid #e1e8ee;
    /* padding: 20px 20px 20px 78px; */
    color: #5e6977;
    font-size: 15px;
    font-weight: 400;
  }
  h1.heading-Mainpage {
    font-size: 50px;
  }
  /* .greenLogo img {
    bottom: 168px !important;
    left: 33% !important;
    width: 125px;
  } */

  .closebtn {
    display: block;
  }
  label.form-check-label {
    font-size: 13px;
  }
  .form-group label:before {
    padding: 5px;
  }
  /* Checkout Page */

  .checkout-page .col {
    margin-right: 5px !important;
  }

  .login-fileds-checkout {
    padding-right: 7px !important;
  }

  .Billing-address-box {
    margin-right: 5px;
  }
  .items-list {
    width: 283px !important;
  }
  .checkout-page .items-list .image img {
    width: 228px !important;

    margin-left: -16px !important;
  }
}
@media (min-width: 375px) and (max-width: 767px) and (max-height: 812px) {
  /* .greenLogo img {
    bottom: 138px !important;
    left: 33% !important;
    width: 125px;
  } */
}
@media (min-width: 375px) and (max-width: 767px) and (min-height: 900px) and (max-height: 926px) {
  .scarves-details ul.menu.left,
  .scarves-details ul.menu.right {
    /* margin-top: -119px; */
  }
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: 139px;
    right: 0px;
    width: 5rem;
  }
}
@media (min-width: 400px) and (max-width: 425px) {
}
@media (max-width: 767px) {
  .heading-Mainpage {
    margin-top: -10% !important;
  }

  .closebtn {
    display: block;
  }

  .overlay a {
    font-size: 20px !important;
  }

  /* ***********************
      Main Header
************************** */

  .slide- {
    min-height: 768px;
    background: #0b4c45 !important;
  }
  .slide- #overlay {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1079px) {
  .slide- {
    height: 83vh;
  }

  .StripeElement {
    margin-top: 60px !important;
  }

  button.add-to-cart-btn {
    padding: 5px 23px !important;
  }
  button.btn.btn-danger.view-myacc-bnt {
    background: #0b4c45;
    font-size: 11px;
    padding-bottom: 20px;
    /* font-size: 0.55rem; */
  }

  h1.whypage-heading {
    color: #96694c;
    font-size: 2.8rem;
    font-weight: 600;
  }
  .whypage-sub-heading {
    color: #808080 !important;
    font-family: "Fira Sans";
    font-size: 12px;
    line-height: 27px;
  }
  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-page .center-form {
    padding: 0px 25px;
    margin-top: 0px;
  }
  .form-area.container {
    top: 79px;
    position: relative;
  }
  a.login-here.center {
    font-size: 10px;
    margin-left: auto;
    width: fit-content;
    display: block;
  }
  .auth-page label.form-check-label {
    font-size: 12px;
  }
  .form-group label:before {
    padding: 6px;
  }
  .bottom-right-logo {
    position: absolute;
    bottom: -15% !important;
    right: -8px !important;
    width: 6rem !important;
  }

  .closebtn {
    display: block !important;
  }
  .overlay .closebtn {
    top: 3px;
  }
  /* CSS rules here */

  /* ***********************
      Landing page with Landing Header
************************** */
  .overlay a {
    font-size: 19px !important;
  }

  .heading-Mainpage {
    margin-top: -10% !important;
  }
  /* .greenLogo img {
    bottom: 160px !important;
    left: 41% !important;
    width: 120px;
  } */

  .closebtn {
    display: block;
  }

  /* ***********************
      Main Header
************************** */

  img.logo {
    width: 120px;
    height: 105px;
  }

  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    top: 180px;
    left: 0%;
    font-size: 1.5rem;
  }
  .footer-centerPart p {
    font-size: 17px;
  }
  .scarves-page .sub-heading {
    transform: translate(-3rem, -26rem);
    font-size: 1rem;
  }
  .scarves-page .shop-now {
    font-size: 1rem;
  }

  .bottom-right-logo {
    position: absolute;
    bottom: -15%;
    right: -8px;
    width: 6rem;
  }

  li.menu_i img {
    margin-left: -36px;
    width: 94px;
    margin-top: -40px;
  }

  /* ***********************
      Responsive
************************** */

  /* ***********************
      Responsive
************************** */

  /* ***********************
      Responsive
************************** */
}
@media (min-width: 992.1px) and (max-width: 1023.9px) {
  /* ***********************
      Landing page with Landing Header
************************** */
  .heading-Mainpage {
    margin-top: -10% !important;
  }
  .greenLogo img {
    bottom: 140px !important;
    left: 41% !important;
  }
  .closebtn {
    display: block;
  }

  .overlay a {
    font-size: 25px !important;
  }

  /* ***********************
      Main Header
************************** */

  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    top: 78%;
    left: 37.5%;
  }
  .footer-centerPart p {
    font-size: 17px;
  }
}
@media (min-width: 1024px) and (max-width: 1080px) and (min-height: 810px) {
  .overlay a {
    font-size: 20px !important;
  }
  .auth-page .main-heading {
    line-height: 44px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-page .center-form {
    padding: 0px 25px;
    margin-top: 0px;
  }
  .form-area.container {
    top: 79px;
    position: relative;
  }
  a.login-here.center {
    font-size: 10px;
    margin-left: auto;
    width: fit-content;
    display: block;
  }
  .auth-page label.form-check-label {
    font-size: 12px;
  }
  .form-group label:before {
    padding: 6px;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
    margin-left: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  button.btn.btn-danger.view-myacc-bnt.buttonCancelOrder {
    font-size: 0.9rem !important;
  }

  .heading-Mainpage {
    top: 55%;
    font-size: 58px;
  }

  /* ***********************
      HomePage
************************** */

  img.logo {
    width: 100px;
  }
  .home-page .sub-heading {
    top: 176px;
    font-size: 1.5rem;
    left: 7px;
  }
  .footer-centerPart p {
    font-size: 17px;
  }
  .overlay a {
    font-size: 20px !important;
  }

 

  .attribute-main {
    width: 100%;
  }

  input.input-felid.qty {
    height: 45px;
  }
  .scarves-page .sub-heading {
    transform: translate(-5rem, -18.7rem);
    font-size: 1.5rem;
  }

  /* .bottom-right-logo {
  position: absolute;
  bottom: -10%;
  right: -18px;
  width: 10rem;
} */
  .bottom-right-logo,
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: 1%;
    /* right: -18px; */
    width: 8rem;
  }

  .scarves-page .shop-now {
    font-size: 1.2rem;
  }

  .center-form.slide-in {
    height: 518px;
    overflow-y: auto;
  }
  .auth-page .main-heading {
    font-size: 2rem;
  }

  .bghjHX {
    margin: 0px 0 0 0 !important;
  }
 

  button.add-to-cart-btn {
    font-size: 15px;
  }
  h3.attribute-heading {
    font-weight: 500;
    padding-top: 13px;
  }

  .price span {
    position: relative;
    left: 1px;
    color: #e1523e;
  }

  .items-list {
    width: 332px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  a.right.carousel-control {
    margin-top: 350px;
    margin-right: -15px;
    height: -moz-fit-content;
    height: fit-content;
  }

  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-page .center-form {
    padding: 0px 25px;
    margin-top: 0px;
  }
  .form-area.container {
    top: 79px;
    position: relative;
  }
  .signup .login-here {
    width: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: -10px;
    right: 0px;
    width: 9rem;
  }
  img.logo {
    width: 100px;
  }
  .auth-page .center-form {
    padding: 0 92px;
    margin-top: 0px;
  }
  .overlay a {
    font-size: 22px !important;
  }
  .forgot-page {
    margin-top: 6% !important;
  }
  .signup img.logo {
    width: 120px;
    /* height: 131px; */
    margin-bottom: 10px;
    margin-top: -92px;
  }
  .main-heading {
    font-size: 2rem;
  }

  /* ***********************
      Landing page with Landing Header
************************** */
  .heading-Mainpage {
    margin-top: 0% !important;
    font-size: 65px;
  }
  /* .greenLogo img {
    bottom: 139px !important;
    left: 44% !important;
  } */

  /* ***********************
      Main Header
************************** */
  /* .headerCenter {
    flex-direction: column;
  } */

  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    /* top: 70%;
    left: 43%; */
    font-size: 1.5rem;
  }
  .footer-centerPart p {
    font-size: 17px;
  }

  /* ***********************
      play page
************************** */

  .playpage-center-text {
    margin-left: 60px;
  }
 
  .scarves-page .sub-heading {
    transform: translate(-3rem, -3.5rem);
    font-size: 1.5rem;
  }

  .bottom-right-logo {
    bottom: 0px;
  }
  .stockists .bottom-right-logo {
    left: 2px !important;
    bottom: 55px;
  }
  li.menu_i img {
    margin-left: -24px;
    width: 103px;
    margin-top: -40px;
  }
  ul.menu.left li.menu_i img {
    margin-left: -31px;
    width: 99px;
    margin-top: -32px;
  }
  ul.menu.right {
    margin-top: -80px;
  }

  .product-img {
    width: 100%;
    height: 310px;
    object-fit: cover;
    object-position: center center;
  }

  .cart-items {
    overflow-y: auto;
  }

  
  .bghjHX {
    margin: 0px 0 0 0 !important;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) and (max-height: 811px) {
  .overlay a {
    font-size: 19px !important;
  }
  .heading-Mainpage {
    margin-top: 0% !important;
    font-size: 65px;
  }

  .home-page .sub-heading {
    /* top: 85%;
    left: 43%; */
    margin-top: 22em;
    font-size: 1.5rem;
  }

  .stockists .bottom-right-logo {
    left: 16px !important;
    bottom: -17px;
  }
  li.menu_i img {
    margin-left: -24px;
    width: 103px;
    margin-top: -40px;
  }
  ul.menu.left li.menu_i img {
    margin-left: -31px;
    width: 99px;
    margin-top: -32px;
  }
}
@media (min-width: 1440.1px) and (max-width: 1550px) {
  .cart-hyper {
    /* width: fit-content; */
    color: transparent;
  }
  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    top: 0%;
    left: 0%;
  }
  .footer-centerPart p {
    font-size: 17px;
  }

  .overlay a {
    font-size: 25px !important;
  }

  .home-page .sub-heading {
    margin-top: 22em;
    font-size: 1.5rem;
  }
}
@media (min-width: 1199px) and (max-width: 1200px) {
  /* ***********************
      Landing page with Landing Header
************************** */
  .heading-Mainpage {
    margin-top: -8% !important;
  }
  /* .greenLogo img {
    bottom: 127px !important;
    left: 43% !important;
  } */
  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    top: 78%;
    left: 40%;
  }
  .footer-centerPart p {
    font-size: 17px;
  }
  .overlay a {
    font-size: 25px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1280px) {
  .overlay a {
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1080px) and (orientation: landscape) {
  .closebtn {
    display: block !important;
  }
  .overlay a {
    font-size: 17px !important;
  }

  a.right.carousel-control {
    margin-right: 15px !important;
  }

  .Search_result-content {
    left: -115px !important;
    width: 345px;
  }
}

@media (min-width: 1441px) and (max-width: 1509px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: -17px;
    right: 16px;
    width: 7rem;
  }
  .center-form.slide-in {
    height: fit-content;
  }
  .auth-page .center-form {
    padding: 0 92px;
    margin-top: 0px;
  }
  .overlay a {
    font-size: 18px !important;
  }
  .forgot-page {
    margin-top: 6% !important;
  }
  .signup img.logo {
    width: 120px;
    /* height: 131px; */
    margin-bottom: -20px;
    margin-top: -52px;
  }
  .main-heading {
    font-size: 2rem;
  }

  /* ***********************
          Landing page with Landing Header
    ************************** */
  .heading-Mainpage {
    margin-top: 0% !important;
  }

  .bottom-right-logo {
    bottom: -90px;
  }
  .bghjHX {
    margin: 0px 0 0 0 !important;
  }
}

@media (min-width: 1551px) and (max-width: 1600px) {
  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-page .center-form {
    padding: 0px 25px;
    margin-top: 0px;
  }
  .form-area.container {
    top: 79px;
    position: relative;
  }
  .signup .login-here {
    width: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  /* ***********************
      HomePage
************************** */

  .home-page .sub-heading {
    top: 80%;
    left: 42%;
  }
  .overlay a {
    font-size: 25px !important;
  }
}

@media (min-width: 1919.1px) and (max-width: 1920px) {
  .auth-page.row {
    min-height: 100vh !important;
  }
  .auth-right-col .center {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  .auth-page img.logo {
    width: 123px;
    /* height: 131px; */
    margin-bottom: 0;
    margin-top: 0;
  }
  .auth-page .main-heading {
    font-size: 33px;
    line-height: 30px;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-right-col input {
    height: 37px;
    font-size: 12px;
  }
  .auth-page form {
    margin-top: 16px;
  }
  .play-page .product-play-img {
    width: 900px;
  }
}

@media (min-width: 1601px) and (max-width: 1919.9px) {
  .auth-page .main-heading {
    line-height: 40px;
    font-size: 27px;
  }
  .auth-page .sub-heading {
    color: #fff;
    font-size: 12px;
    text-align: left;
  }
  .auth-right-col input {
    height: 32px;
    font-size: 12px;
  }
  .auth-page .footer {
    bottom: 5px;
  }
  .auth-left-col {
    background-position: center;
  }
  .auth-page img.logo {
    margin-top: 0 !important;
  }
  .auth-page.row {
    min-height: 100vh !important;
    overflow: auto;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }

  .auth-page .center-form {
    padding: 0px 25px;
    margin-top: 0px;
  }
  .form-area.container {
    top: 79px;
    position: relative;
  }
  .signup .login-here {
    width: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  .scarves-details .bottom-right-logo {
    position: absolute;
    bottom: 205px;
    right: 0px;
    width: 7rem;
  }
  img.logo {
    width: 125px;
  }
  /* ***********************
      Landing page with Landing Header
************************** */
  .heading-Mainpage {
    margin-top: -1% !important;
  }

  .overlay a {
    font-size: 25px !important;
  }

  .stockists .bottom-right-logo {
    left: 2px !important;
    bottom: 141px;
  }
}
@media (min-width: 2000px) and (max-width: 2400px) {
  .height-page {
    height: 100%;
    min-height: 100vh;
  }
  .why-page {
    min-height: 100vh;
  }
  #overlay {
    top: 10% !important;
  }

  .scarves-details .center-page {
    height: 100%;
    min-height: 100vh;
  }
  .auth-page.row {
    min-height: 100vh;
  }
  .my-account-page {
    min-height: 100vh;
  }
}
@media (min-width: 2500px) {
  .auth-page.row {
    min-height: 100vh !important;
  }

  .height-page {
    height: 100%;
    min-height: 100vh;
  }
  .my-account-page {
    min-height: 100vh;
  }

  .bottom-right-logo {
    bottom: 240px;
  }
  .stockists .bottom-right-logo {
    left: 50px !important;
  }
  .home-page .sub-heading {
    /* top: 1424px; */
    margin-top: 29em;
  }
  .why-page {
    height: 100%;
    transition: 0.5ms;
    min-height: 100vh;
  }

  #overlay {
    top: 10% !important;
  }

  /* .greenLogo img {
    position: absolute;
    bottom: 160px;
    width: 220px;
    left: 46%;
  } */

  .heading-Mainpage {
    font-size: 6rem;
  }

  .overlay a {
   }
  .scarves-page .sub-heading {
    transform: translate(0rem, 38.5rem);
  }
  .scarves-details .center-page {
    height: 100%;
    min-height: 100vh;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 776px;
    object-fit: cover;
  }
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px !important;
  }
  .carousel-indicators li {
    
    margin: 41px 6px -4px 6px;
  }
  .slider-text {
    background: #e1dfdb;
    margin-top: -82px;
    z-index: 999;
    opacity: 0.5;
    position: relative;
    padding: 16px;
    margin-bottom: 17px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 673px) and (max-height: 769px) {
  /* CSS rules go here */
  .home-page .sub-heading {
    /* top: 94%;
    left: 43%; */
    font-size: 1.5rem;
  }
  .auth-page .center-form {
    margin-top: 0px;
    padding: 0px 92px;
  }
  .signup img.logo {
    width: 76px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .auth-page .main-heading {
    line-height: 28px;
  }
  .auth-page label.form-label {
    font-size: 12px;
  }
  .main-heading {
    font-size: 1.7rem;
  }
  .auth-right-col input {
    height: 37px;
    font-size: 12px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;

    padding: 0% 3% 4.5% 3%;
  }
  li.menu_i img {
    margin-left: -24px;
    width: 103px;
    margin-top: -40px;
  }
  ul.menu.left li.menu_i img {
    margin-left: -31px;
    width: 99px;
    margin-top: -32px;
  }

  /* .greenLogo img {
    bottom: 150px;
  } */
}

@media screen and (min-width: 1366px) and (min-height: 600px) and (max-height: 700px) {
  .footer.row {
    bottom: 0;
    top: 107%;
  }
  .footer {
    top: 105%;
  }
  .auth-page img.logo {
    width: 93px !important;
    /* height: 131px; */
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .form-area.container {
    top: 90px;
    position: relative;
  }
  .auth-page.row {
    min-height: 118vh !important;
  }
  .overlay a {
    font-size: 13px !important;
  }
  .auth-right-col input {
    height: 30px;
    font-size: 11px;
  }
  .auth-page .submit-button {
    padding: 0.3rem 0 1rem 0;

    font-size: 13px !important;
  }
  li.menu_i img {
    margin-left: -24px;
    width: 103px;
    margin-top: -40px;
  }
  ul.menu.left li.menu_i img {
    margin-left: -31px;
    width: 99px;
    margin-top: -32px;
  }
}
.auth-page.row {
  min-height: 100vh;
  overflow: auto;
}
@-moz-document url-prefix() {
  @media (min-width: 1280px) and (max-width: 1440px) {
  }
}
 
@media screen and (min-width: 768px) and (max-width: 810px) {
  .slider-text {
    padding: 16px 7px !important;
    width: 94% !important;
    margin-bottom: 17px !important;
  }
  button.minus-btn {
    /* padding-right: 15px !important; */
    padding: 0;
  }
  button.plus-btn {
    /* padding-right: 15px; */
    padding: 0;
  }
  .plus-btn img {
    margin-top: -4px;
  }

  
  .price span {
    font-size: 21px;
  }
  h3.attribute-heading {
    font-weight: 400;
  }
  .bghjHX {
    margin: 0px 0 0 0 !important;
  }

  #scarfs {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  #stripes {
    margin-top: 20px;
  }

 

  button.add-to-cart-btn {
    font-size: 14px;
  }

  .stockists-menu ul.menu.left li.menu_i img {
    display: block;
    margin-top: 0px;
  }
  .product-img {
    height: 310px;
  }

  span.menu_i_tx.connect {
    width: 0;
    height: 86px;
  }
  a.right.carousel-control {
    margin-top: 350px;
    margin-right: 15px;
    height: -moz-fit-content;
    height: fit-content;
  }
  a.left.carousel-control {
    margin-left: -7px;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 94%;
    height: 700px;
    object-fit: cover;
    object-position: center;
  }
  .product-title-details-page {
    color: #e1523e;
    font-family: "Rosarivo";
    font-size: 31px;
    text-transform: uppercase;
    position: relative;
    bottom: -18px;
  }

  .slider-text {
    padding: 16px 7px !important;
    width: 94% !important;
    margin-bottom: 17px !important;
  }

  #msform .fieldset3 {
    margin-left: 23px;
  }
  .checkout-page .items-list .image img {
    object-position: center center !important;
  }
  .login-fileds-checkout {
    padding-right: 0px;
  }

  .checkout-page .items-list .image img {
    width: 232px !important;
    height: 135px;
    object-fit: cover;
    border-radius: 15px;
    object-position: bottom center;
  }

  .items-list {
    width: 250px !important;
  }
}
@media screen and (min-width: 770px) and (max-width: 799px) {
  .slider-text {
    padding: 16px 7px !important;
    width: 94% !important;
    margin-bottom: 17px !important;
  }
}


@media screen and (min-width: 993px) and (max-width: 1079px) {
}

@media screen and (min-width: 1500px) and (max-width: 1549px) {
  .auth-page .center-form {
    padding: 0 116px;
    margin-top: 0px;
  }
  .auth-page.row {
    min-height: 870px;
    overflow: auto;
  }
  .auth-page img.logo {
    margin-top: 0px;
  }
  /* .form-area.container {
    top: 44px;
    position: relative;
} */
  .overlay a {
    font-size: 20px !important;
  }

  .auth-right-col .center {
    margin-top: -30px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  div.searchForm input {
    width: 120px;
  }
  div.searchForm {
    margin-left: -70px;
  }
  img.cart-icon {
    width: 30px;
    margin-left: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  div.searchForm input {
    width: 120px;
  }
  div.searchForm {
    margin-left: -70px;
  }
  img.cart-icon {
    width: 30px;
    margin-left: 20px;
  }
  .landing-page-icons {
    position: absolute;
    top: 0;
    left: 55px;
    padding: 4.5% 3% 0% 3%;
  }
  .landing-icon-enterSite {
    padding: 4.5% 3% 0% 3%;
    position: absolute;
    right: 5%;
    width: 100%;
  }
  .login-fileds-checkout {
    padding-right: 0px;
  }
  .checkout-page .left-side {
    padding: 0 !important;
  }
  #msform fieldset {
    padding: 0 0 0 60px !important;
  }
  .shipping-top-heading {
    padding-left: 60px !important;
  }
  .checkout-page .main-heading {
    font-size: 2.3rem !important;
  }
  .items-list {
    width: 291px !important;
  }
  .checkout-page .items-list .image img {
    width: 240px !important;
  }
  .contiune-shoping {
    text-align: left;
    position: absolute;
    right: 10px;
    top: 0;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 55%;
    top: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 810px) and (min-height: 864px) {
  .Billing-address-box {
    /* margin-left: 24px !important; */
  }
  .items-list {
    width: 246px!important;
}
.checkout-page .items-list .image img {
  width: 200px !important;
}
button.btn.btn-danger.view-myacc-bnt {
  background: #0b4c45;
  /* font-size: 8px; */
  padding-bottom: 4px;
  font-size: 0.55rem;
}
}