/* Header styling */
.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2.5rem;
  padding: 4.5% 3% 0% 3%;

}
.overlay-content.menuitem .active{
color: #fff !important;
}

/* .main-headerRight img:hover + .overlay {
  height: 50%  !important;
  
} */

.main-header {
  position: relative;
  top: 0;
  padding: 20px 0;
  width: 100%;
  background-color: #0B4C45;
}

/* Header left area */
.headerLeft {
  float: left;

}

.main-headerLeft {
  padding: 2% 3% 2% 3%;
}

/* menu ICON styling*/
.menuIcon {
  cursor: pointer;
  z-index: 99;
  position: relative;
}

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #0B4C45;
  overflow-y: hidden;
  transition: 0.5s;
}



.overlay-content {
  position: relative;
  top: 1%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 25px;
 
  color: #FF8D6B;
  display: block;
  font-weight: 600;
  transition: 0.3s;
}

.overlay .menuitem a {
  border-bottom: #FF8D6B solid;
}
.overlay .menuitem a:last-child{
border-bottom: none !important;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 0px;
  right: 45px;
  font-size: 60px;
  z-index: 9;
}

@media screen and (max-height: 450px) {
  .overlay {
    overflow-y: auto;
  }

  .overlay a {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

/* header center Area */
.headerCenter {
  display: flex;
  /* margin-left: 234px; */
  justify-content: center;
}

.auth-page img.logo {
  width: 123px;
  /* height: 131px; */
  margin-bottom: -11px;
  margin-top: 62px;  margin-left: -70px;

}
 

/* header Right Area */
.headerRight img {
  float: right;
}

.main-headerRight img {
  float: right;
  z-index: 10;
  position: relative;
}

.main-headerRight {
  padding: 2% 3% 2% 3%;
}

#page-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 15rem;
}
/* Footer Styling */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  padding: 0% 3% 4.5% 3%;

}

.main-footer {
  position: absolute;
  bottom: 0;
  height: 15rem;
  width: 100%;
  background-color: #0B4C45;
}

.footer .overlay {


  bottom: 0;
  top: auto;


}

.main-footer .overlay {


  bottom: 0;
  top: auto;


}

.footer-centerPart {
  display: flex;
  justify-content: center;
}

.footer-centerPart p {
  background-color: #fff5e6;
  font-size: 25px;
  width: fit-content;
  height: fit-content;
  margin-top: 60px;
  font-weight: 600;
  font-family: 'Adobe Caslon Pro';
  padding: 0 20px;
  border: #96694C solid;

}

.footer-centerPart img {
  width: 100px;
  height: 100px;
  margin-top: 30px;
}

.copyright-text p {
  text-align: center;
  letter-spacing: 0px;
  color: #999999;
}

.social-icons {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.social-icons a {
  margin-right: 8%;
}

.social-icons img {
  width: 20px;
}
 
div.searchForm input {
  background: transparent;
  border: none ;
  border-bottom: #FF8D6B solid 2px;
  color: #fff;
}

div.searchForm {
  /* margin-top: 30px; */
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0% 0% 0% 0%;
  height: -moz-fit-content;
  height: fit-content;
  /* right: -50%; */
  width: 100%;
  /* margin-top: auto; */
}
div.searchForm input:focus {
  background: transparent;
  border: none;
  border-bottom: #FF8D6B solid 2px;
}

div.searchForm input:focus-visible {
  outline: transparent;
}

img.cart-icon {
  width: 40px;
  margin-left: 32px;
 
}

.cart-hyper {
  color: transparent;
}

.cart-hyper:hover {
  color: transparent;
}
.closebtn{
  display: none;
}

.Search_result-content {
  position: absolute;
  background: #fff;
  width: 418px;
  left: 0;
  top: 63px;
  height: auto;
  overflow-y: auto;
  min-height: 168px;
  max-height: 500px;
}
.Search_result-content .not-found {
   display: flex;
  justify-content: center;
  padding-top: 61px;
}