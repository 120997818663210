.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.scrollarea {
  overflow-y: auto;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #96694C;
  --bs-btn-hover-border-color: #96694C;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #96694C;
  --bs-btn-active-border-color: #96694C;
}
.bd-mode-toggle {
  z-index: 1500;
}
.d-flex.flex-column.flex-shrink-0.p-3.bg-body-tertiary {
  height: 79vh;
  border: 1px solid #d2d2d2;
  border-left: none;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  /* margin: 50px 50px 50px -122px; */
  width: 350px;
  padding: 26px 0px 0px 27px !important;
}
ul.nav.nav-pills.flex-column.mb-auto.tabs li {
  padding-top: 35px;
}
.tabs .active a {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #0b4c45;
}
.tabs a {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #a0a0a0;
}
.d-flex.flex-shrink-0.p-3.content-box {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 50px;
  flex-direction: column;
  margin-right: 50px;
}
a {
  color: #a0a0a0;
}
a:hover {
  color: #0b4c45;
}
.tab-heading {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 30px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #0b4c45;
}
.row.main-cards .card-item {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
  /* margin-left: 50px; */
  margin-top: 20px;
  padding: 8px 29px;
}
.row.main-cards {
  justify-content: space-around;
}
.row.main-cards .card-title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 37.3147px;
  line-height: 45px;
  /* display: flex; */
  align-items: center;
  color: #0b4c45;
}
.row.main-cards .card-heading {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Theme Color/Grey Body */

  color: #464255;
}
h4.card-sub-heading {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a3a3a3;
}
.icon-side-myacocunt {
  height: 79vh;
  background-color: #96694C;
  border: 1px solid #d2d2d2;
  border-right: none;
  border-radius: 5px;
  padding: 22px 4px 0px 4px;
  z-index: 999;
  position: relative;
  /* margin: 50px 0px 50px 134px; */
  width: -moz-fit-content;
  width: fit-content;
}
a.d-flex.align-items-center.link-body-emphasis.text-decoration-none {
  padding-bottom: 13px;
  padding-left: 12px;
}
.acc-left-sidebar {
  margin: 50px;
}
.nav-link:hover, .nav-link:focus {
  color: #0b4c45;
}
.d-flex.flex-column.flex-shrink-0.p-3.bg-body-tertiary li {
  margin-left: 10px;
}
