.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}
.bd-mode-toggle {
  z-index: 1500;
}
.checkout-page .form-control {
  border: solid #e9e9e9 1px;
}
.container {
  max-width: 960px;
}
.height-page {
  height: 100%;
}
.parent-side-heading-checkout{
  position: relative;
}
.checkout-page .form-control {
  color: #000;
}
 
.checkout-page .main-heading {
  color: #96694C;
  position: absolute;
  font-size: 2.8rem;
  transform: rotate(180deg);
  font-weight: bold;
  left: -5%;
  top: 67px;
  z-index: 9;
  writing-mode: vertical-rl;
}
.checkout-page .left-side {
  padding: 2rem 0rem 2rem 3rem;
  flex-direction: column;
  display: flex;
  border-top: solid #ff8d6b;
}
.checkout-page .left-side input.form-control,
.checkout-page .left-side select.form-control {
  height: 60px;
  margin-top: 15px;
}
.checkout-page .feild50 {
  margin-right: 15px;
}
.checkout-page .text-return-info {
  color: #0b4c45;
  font-weight: bold;
}
.return-information {
  margin-top: 4%;
}
.checkout-page button.add-to-cart-btn {
  float: right;
  margin-top: 0;
}
.checkout-page .item {
  height: auto;
}
 
.checkout-page .items-list .description {
  margin-left: 3%;
}
.checkout-page .items-list .total-price {
  font-weight: bold;
}
.topbottomLine {
  margin-left: auto;
  margin-right: auto;
  width: 79%;
}
.checkout-page .checkout-total-area .subtotal {
  display: flex;
  justify-content: right;
}
.checkout-page .checkout-total-area {
  float: right;
  margin-right: 11%;
}
.checkout-page .checkout-total-area h1.price {
  padding: 0 0 0 51px;
}
.checkout-page .checkout-total-area h1 {
  font-size: 25px;
}
.checkout-page .right-side {
  flex-direction: column;
  /* justify-content: center; */
  display: flex;
}

.items-list {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  background: hsl(30deg 41.67% 95.29%);
  margin-top: -20px;
  border-radius: 15px;
  height: 650px;
  overflow-y: auto;
}
.checkout-page .items-list .image img {
  width: 300px;
  height: 135px;
  object-fit: cover;
  border-radius: 15px;
  object-position: center center;
}
.shopping-bag-top-head {
  display: flex;
  justify-content: space-around;
  padding: 11px 0px;
  color: #E1523E;
}
.checkout-page .right-side .product-name {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.checkout-page .description {
  width: 100%;
}
.checkout-page .left-side,
.checkout-page .right-side {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* ******************************
          Multi Steps Form
**********************************/

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 30px;
  padding-bottom: 5%;
}

#msform fieldset {
  background: none;
  border: 0 none;
  border-radius: 0px;
  padding: 0 100px;
  margin-top: -13px;
  position: relative;
}

/*Hide all except first fieldset*/
/* #msform fieldset:not(:first-of-type) {
  display: none;
} */

/*inputs*/
#msform input, #msform textarea, #msform  select {
  padding: 15px;
  border: 2px solid #fae8de;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  color: #000000b3;
  font-size: 13px;
}
#msform ::placeholder {
  color: #e2e8ec;
  opacity: 1; /* Firefox */
  text-align: left;
}
 
#msform :-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #e2e8ec;
}

#msform ::-ms-input-placeholder { /* Microsoft Edge */
 color: #e2e8ec;
}
#msform input:focus,
#msform textarea:focus, #msform select:focus{
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #ff8d6b;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: 100px;
  background: #E1523E;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #E1523E;
}

#msform .action-button-previous {
  width: 100px;
  background: #c5c5f1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #c5c5f1;
}

/*headings*/
.fs-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  padding-top: 30px;
  counter-reset: step;
}
.checkout-page .progressbar-items {
  position: relative;
  top: -60px;
   left: 50%;
   
}
#progressbar li {
  list-style-type: none;
  color: #E1523E;
  text-transform: uppercase;
  font-size: 15px;
  width:24%;
  float: left;
  position: relative;
 }

#progressbar li:before {
  content: "";
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  border: #E1523E solid;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  border: dashed 1px;
  position: absolute;
  left: -50%;
  top: 11px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #E1523E;
  color: white;
  border: solid 2px #E1523E;
}

/* Not relevant to this form */
.dme_link {
  margin-top: 30px;
  text-align: center;
}
.dme_link a {
  background: #fff;
  font-weight: bold;
  color: #E1523E;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 25px;
  font-size: 12px;
}

.dme_link a:hover,
.dme_link a:focus {
  background: #fff;
  text-decoration: none;
}
.shipping-top-heading {
  text-align: left;
  font-size: 11px;
  position: relative;
  color: #808080;
  padding-left: 100px;
  margin-bottom: 10px ;
}
.shipping-sub-top-heading {
  text-align: left;
  font-size: 11px;
  position: relative;
  color: #808080;
  padding-left: 100px;
  margin-bottom: 10px;
}
.Billing-address-box {
  border: 2px solid #fae8de;
  padding: 10px;
  border-radius: 6px;
}

.Billing-address-box span.label-text{
color: #808080;
font-size: 12px;
}
.Billing-address-box .checkbox-wrapper-37 .checkbox-svg {
  width: 25px;
  border-radius: 5px;
  border: solid 2px #fae8de;
  height: 25px;
}
.Billing-address-box .checkbox-wrapper-37 .checkbox-box {
  stroke: transparent;
 
}
.Billing-address-box .thank-you-page {
  color: #96694C;
  text-align: center;
}