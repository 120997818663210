/* HomePage */
@font-face {
  font-family: "Adobe Caslon Pro";
  src: local("Adobe Caslon Pro"),
    url("../../assets/fonts/ACaslonPro-Bold.otf") format("otf");
}

@import url("https://fonts.googleapis.com/css?family=Rosarivo");
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
 
}
body {
  font-family: "Fira Sans";
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Adobe Caslon Pro";
}
a {
  text-decoration: none;
}

/*
 *  STYLE 2
 */

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #0b4c45;
}

.heading-Mainpage {
  font-size: 5rem;
  font-weight: 600;
  position: relative;
  display: flex;
  color: #96694c;
  justify-content: center;
  top: 67%;
}
.sub-heading-Mainpage {
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  /* position: relative; */
  /* display: flex; */
  color: #df8466;
  justify-content: center;
  font-family: "Adobe Caslon Pro";
  top: 84%;
  left: 89px;
}
.mainHomepage {
  /* background-image: url("../../assets//images/landing-page-bg.png"); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
}
.greenLogo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 10%;
}
.greenLogo img {
  width: 150px;
}
.greenLogo img,
.greenLogo a {
  /* position: fixed; */
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
/* row */
.row {
  --bs-gutter-x: 0 !important;
}
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
/*****************************
           Auth Pages
  *******************************/
.auth-right-col input {
  height: 50px;
}
.auth-page {
  background-color: #0b4c45;
}
.auth-left-col {
  background-image: url("../../assets/images/auth-left-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
  /* box-shadow: 11px 4px 54px rgba(0, 0, 0, 0.56); */
}
.auth-left-col.col .left-logo {
  position: relative;
  top: 7%;
  left: 7%;
}
/* .auth-right-col .center {
  display: flex;
  justify-content: center;
  margin-top: -30px;
} */
.auth-right-col .center {
  display: flex;
  justify-content: center;
  /* margin-top: -30px; */
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.auth-page .header,
.auth-page .footer {
  width: 50% !important;
}
.form-area.container {
  top: 100px;
  position: relative;
}
.auth-page .sub-heading {
  color: #fff;
  font-size: 15px;
  text-align: left;
}
.auth-page .center-form {
  padding: 0 11%;
  margin-top: 0px;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: center;
}
.buy-text-after-slider {
  margin-top: 10px;
  display: flex;
  text-align: right;
  flex-direction: column;
  margin-right: 2%;
  justify-content: end;
  float: right;
}
.buy-text-after-attribute {
  margin-top: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 3%;
  justify-content: end;
  float: left;
}
.buy-text-after-slider a {
  font-size: large;
  font-weight: 700;
  font-family: "Rosarivo";
  color: #e1523e;
}
.product-price-slider {
  font-size: large;
  font-weight: 700;
  font-family: "Adobe Caslon Pro";
  color: #e1523e;
  letter-spacing: 2px;
  font-size: 25px;
}

.fcdNWp {
  margin: 0 !important;
}
.ml-1.col {
  margin-left: 15px;
}
.forgot-page {
  margin-top: 12% !important;
}
.auth-page .main-heading {
  font-family: "Rosarivo";
  font-style: normal;
  font-weight: 400;
  line-height: 51px;
  /* identical to box height */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #96694c;
  -webkit-text-fill-color: transparent;
}
.auth-page form {
  font-family: "Fira Sans";
  color: #fff;
  margin-top: 35px;
}
.auth-page label {
  font-family: "Fira Sans";
  color: #fff;
 
}
.auth-page .submit-button {
  background-color: #96694c;
  width: 100%;
  padding: 0.6rem 0 1.8rem 0;
  border: none;
  font-size: 14px !important;
  border-radius: 6px;
  color: #fff;
}
.form-control {
  background-color: transparent;
  border: 1px solid #96694c;
  color: #fff;
  font-size: 0.8rem !important;
}
.form-control:focus {
  color: #fff;
  background-color: transparent;
  border: 1px solid #96694c;
  outline: 0;
  box-shadow: none;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.form-check {
  padding-left: 0px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 13px;
}

.form-group label:before {
  content: "";
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.buy-text-after-slider a:hover {
  color: #ff8d6b !important;
  text-decoration: none;
}
.auth-right-col form a {
  color: #fff;
}

.login-here {
  color: #96694c !important;
  text-decoration: underline;
}

small.text-muted.form-text {
  color: #ff0000bf !important;
}

/* ****************************
             Sidebar menu
*******************************/
/* left */
.menu.left {
  position: absolute;
  display: block;
  top: 7%;
  left: 0px;
  z-index: 8;
  padding: 2% 3% 2% 2%;
}
.plus-icon img {
  width: 20px;
}
.menu_i {
  display: block;
  width: 25px;
  line-height: 25px;
  padding: 30px 0;
  margin: 10px 0 0;
}

.menu_i_tx {
  display: inline-block;
  vertical-align: top;
  transform: translate(0, 100%) rotate(-90deg);
  transform-origin: 0 0;
  white-space: nowrap;
  font-size: 2rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #96694c;
  -webkit-text-fill-color: transparent;
}

.menu_i_tx:after {
  content: "";
  float: left;
  margin-top: 100%;
}

/* right */
.menu.right {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 7%;
  right: 0px;
  z-index: 8;
  padding: 2% 3% 2% 3%;
}
.menuIcon {
  width: 25px;
}
.right .menu_i_tx {
  transform: translate(0, 100%) rotate(-90deg) !important;
  font-size: 2rem;
  -webkit-text-stroke-color: #e1523e !important;
}
.right .menu_i_tx:hover {
  -webkit-text-fill-color: #e1523e !important;
}
.left .menu_i_tx:hover {
  -webkit-text-fill-color: #96694c !important;
}

.left .active .menu_i_tx {
  -webkit-text-fill-color: #96694c !important;
}
.right .active .menu_i_tx {
  -webkit-text-fill-color: #e1523e !important;
}
.slider-text {
  background: #e1dfdb;
  /* margin-top: -83px; */
  z-index: 999;
  overflow-y: auto;

  opacity: 0.8;
  position: relative;
  padding: 16px 10%;
  margin-bottom: 17px;
}
.slider-content-height {
  height: auto;
}
.slider-text p {
  font-size: 12px !important;
  padding: 0 20px;
  color: #000;
  /* height: 500px; */
  font-family: "Adobe Caslon Pro";

  text-align: center;
  margin-top: -10px;
  font-weight: 600;
}
.plus-icon-slider-text {
  float: right;
  cursor: pointer;
}
.plus-icon-slider-text img {
  width: 25px;
  margin-top: -30px;
  margin-right: 30px;
}
.carousel-indicators .active span{
  width: 8px;
  height: 8px;

  background-color: #96694c;
}
.carousel-indicators {
  margin-bottom: 0rem;
}
.carousel-indicators li{
  border: solid 5px transparent;
}
.carousel-indicators li span{
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 20px 5px 0px 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #96694c;
  border: 1px solid #96694c;
  border-radius: 10px;
}

.scarves-details .bottom-right-logo {
  position: absolute;
  bottom: 90px;
  right: 0px;
  width: 9rem;
}
/* *********************
        Home page
************************/
.home-page {
  height: 100vh;
  /* min-height: 768px;
   */
}
.home-page .menu.left {
  padding: 2% 3% 2% 2%;
}
.home-page .left {
  background-image: url("../../assets/images/home-left.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-page .right {
  background-image: url("../../assets/images/home-right.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-page-sub-heading {
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  display: flex;

  justify-content: center;
  position: absolute;
  bottom: 20rem;
  width: 100%;
  align-items: center;
}
h1.sub-heading {
  font-weight: 700;
  color: #ff8d6b;
}
.home-page .sub-heading:hover {
  color: #e1523e;
}
.scarves-page .center-page {
  height: 100%;
  min-height: 768px;
}
.activeStripeAttribute,
.activeScarfAttribute {
  border: solid 3px #96694c;
  border-radius: 5px;
}
#myCarousel .item {
  height: auto !important;
}
.carousel-control.right {
  background-image: none;
  width: fit-content;
}
.carousel-control.left {
  background-image: none !important;
  width: fit-content;
}
/* *********************
      WHY page
************************/

.why-page .leftSide-center-text {
  height: 770px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.whypage-center-text {
  padding: 230px 4% 5% 20%;
}
h1.whypage-heading {
  color: #96694c;
  font-size: 3.5rem;
  font-weight: 600;
}
.whypage-sub-heading {
  color: #808080;
  font-family: "Fira Sans";
  font-size: 15px;
  line-height: 27px;
}
.leftSide-center-text p {
  color: #00000099;
}
.closebtn {
  display: none !important;
}
.why-page {
  height: 100%;
  transition: 0.5ms;
  min-height: 768px;
}
.why-page .right {
  /* background-image: url("../../assets/images/why-right.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.faq .right {
  /* background-image: url("../../assets/images/fqs-page.jpg") !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.why-page .sub-heading {
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  position: absolute;
  display: flex;
  color: #ff8d6b;
  justify-content: center;
  top: 100%;
  left: 44%;
}
.loader-cart {
  position: absolute;
  width: 100vw;
  z-index: 99999;
}
.loader-cart .loader-container {
  background-image: none;
}
.cart-items .item .image img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.auth-page .submit-button:hover {
  background-color: #0b4c45;
  border: solid 1px #96694c;
}
.bottom-right-logo {
  position: absolute;
  bottom: 75px;
  right: 0px;
  width: 9rem;
}
.plus-icon {
  /* float: right; */
  /* margin-top: 0%; */
  cursor: pointer;
  z-index: 9;
}
.cart-items {
  height: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  
}
.stockists .bottom-right-logo {
  left: 2px !important;
  bottom: 15px;
}
/* **********************
          Process Page
**************************/

.process-right {
  /* background-image: url("../../assets/images/process-right.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* *********************
        scarves-page
************************/
.scarves-page {
  height: 100%;
  background-color: #0b4c45;
}
.scarves-page .center-page {
  background-image: url("../../assets/images/scarves-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 4;
}
.product-img {
  width: 100%;
  height: 315px;
  object-fit: cover;
  object-position: center center;
}
.ScarvesLoop {
  position: relative;
}
.spacer {
  padding: 1% 0;
}
.scarves-page .shop-now {
  color: #e1523e;
  text-decoration: none;
  float: right;
  margin-top: -4%;
  z-index: 9999;
  position: relative;
  margin-right: 130px;
  /* font-style: italic; */
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 600;
}
.scarves-page .shop-now:hover {
  color: #96694c;
}
.scarves-page .sub-heading {
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  position: absolute;
  display: flex;
  color: #ff8d6b;
  justify-content: center;
  left: 45%;
  z-index: 999;
  bottom: -36%;
}

/*********************
   stockists
**********************/
.stockist-items li:hover {
  background-color: #f8f3ee;
  width: 100px;
  font-weight: 600;
  cursor: pointer;
  color: #976a4d;
}
.stockist-items {
  list-style: none;
  line-height: 30px;
  color: #808080;
  padding-top: 120px;
  margin-bottom: -50px;
  padding-left: 0px;
}
.stockists .right {
  background-image: url("../../assets/images/stockists.png");
}

/* ********************
      Play Page
*********************/
.play-page {
  height: max-content;
}

.price span {
  font-size: 1.5vw;
  position: relative;
  left: 1px;
  color: #e1523e;
 
}
.play-page .price{
  padding: 10px 0 20px 0;
}
.play-page .heading {
  color: #e1523e;
  font-size: 3vw;
  font-weight: 500;
}

.product-single-img {
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  width: fit-content;
}
h3.attribute-heading {
  color: #e1523e;
  border-bottom: solid 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5vw;
  padding-bottom: 5px;
  font-weight: 600;
}
.attribute-main {
  /* width: 45%; */
  
  margin-top: 2%;
  margin-bottom: 6%;
}
.play-page .right-side {
  margin-top: 5%;
}
input.input-felid.address {
  width: 32% !important;
}
button.remove-input {
  background: none;
  border: none;
  width: fit-content;
  padding: 0 16px;
}
.ship-icon-plus {
  width: 20px;
  padding-right: 5px;
}
.row.play-page {
  padding-bottom: 1%;
}
.corss-icon-plus {
  rotate: 45deg;
  width: 20px;
}
.ship-to-address {
  background: none;
  border: none;
  width: fit-content;
  color: #0b4c45;
}
.qty-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 22px 0 5px;
}
.play-name-title input,
.play-email input,
.play-page .input-felid {
  width: 70%;
  height: 50px;
  border-radius: 5px;
  font-size: 1vw;
  color: #e1523e;
  border: double #80808045 2px;
}

.play-email input {
  width: 45% !important;
}
.play-name-title {
  padding: 2% 0 2% 0;
}
.play-qty .qty {
  width: 10% !important;
  height: 55px !important;
}
input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.arrow-left {
  position: absolute;
  cursor: pointer;
  left: -6%;
  margin-top: 7%;
  width: 1.5vw;
}

.sc-dnqmqq.bVoXXg .image {
  cursor: pointer;
}
.arrow-right {
  transform: rotate(180deg);
  float: right;
  position: absolute;
  /* top: 37%; */
  width: 1.5vw;
  right: 36%;
  z-index: 99;
  margin-top: -13%;
  cursor: pointer;
}
h3.attribute-heading,
.play-page .heading,
.price span,
.play-name-title,
.add-to-cart-area {
  margin-left: 100px;
}
.add-to-cart-area {
  margin-top: -25px;
}
#scarfs [hidden] {
  display: block !important;
  visibility: hidden !important;
}

.jirApZ {
  grid-template-columns: repeat(5, 1fr) !important;
}
.play-page .inner-page {
  margin-top: 3%;
}

.bghjHX {
  margin: 0 0 0 0 !important;
}
.attribute-main {
  width: 100% !important;
  margin-left: 100px;
}
.sc-bZQynM.FvPZw {
  width: 100px !important;
}

.bghjHX {
  overflow: hidden;
}

.checkbox-wrapper-37 {
  display: flex;
  padding: 20px 0 10px 0;
}
.checkbox-wrapper-37 input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper-37 .terms-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-wrapper-37 .terms-label .label-text {
  padding: 0 30px 0 6px;
}
.Billing-address-box .checkbox-wrapper-37 .terms-label .label-text {
  padding: 0px 6px 0 6px;
}
.checkbox-wrapper-37 .checkbox-svg {
  width: 45px;
  height: 45px;
}
.checkbox-box {
  border: solid 3px #00000026;
}
.checkbox-wrapper-37 .checkbox-box {
  fill: #f0eeee;
  stroke: #e1523e;
  stroke-dasharray: 800;
  /* stroke-dashoffset: 0; */
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper-37 .checkbox-tick {
  stroke: #e1523e;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper-37
  input[type="checkbox"]:checked
  + .terms-label
  .checkbox-box,
.checkbox-wrapper-37
  input[type="checkbox"]:checked
  + .terms-label
  .checkbox-tick {
  stroke-dashoffset: 0;
}
input.input-felid.qty {
  width: 80px;
  height: 60px;
}
 

.text-on-image {
  position: absolute;
  text-align: center;
  z-index: 9;
  background: #fff;
  font-size: 0.8vw;
  position: absolute;
  bottom: 29%;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  height: auto;
  overflow: auto;
  padding: 0 10px;
  color: #0b4c45;
  /* transform: translate(166px, 423px); */
  line-height: 24px;
}
.char {
  --offset: calc(var(--char-index) * 2rem);
  offset-path: path("M.4 84.1s127.4 188 267.7 0 247.3 0 247.3 0");
  offset-distance: var(--offset);
  position: absolute;
}

/* **********************
          404
**************************/
.no-found {
  height: auto !important;
  background-color: #0b4c45;
  color: #96694c;
  display: flex;
  font-family: "Adobe Caslon Pro";
  justify-content: center;
  font-size: 9vw;
  padding-top: 10%;
  overflow: hidden;
}
.notFoundTExt {
  height: 27vh !important;
  padding-top: 00%;
  font-size: 5rem;
}
/* **************************
        Cart sliding
**************************** */

.shopping-cart {
  width: 0px;
  display: none;
  height: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 80px auto;
  background: #ffffff;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 3.2%;
  transition: 0.3s;
  transition-delay: 0s;
  flex-direction: column;
}
.title {
  height: 60px;
  border-bottom: 1px solid #e1e8ee;
  padding: 20px 30px;
  color: #e1523e;
  font-size: 18px;
  font-weight: 400;
}
.shopping-cart .title .delete-btn {
  float: right;
}
.item {
  padding: 20px 30px;
  height: 120px;
  display: flex;
}

/* .item:nth-child(3) {
  border-top: 1px solid #e1e8ee;
  border-bottom: 1px solid #e1e8ee;
} */
.buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 60px;
}
.delete-btn,
.like-btn {
  display: inline-block;
  cursor: pointer;
}
.delete-btn {
  width: 18px;
  height: 17px;
  background: url("https://designmodo.com/demo/shopping-cart/delete-icn.svg")
    no-repeat center;
}

.is-active {
  animation-name: animate;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: right;
  }
}
.image {
  margin-right: 50px;
}

.description {
  padding-top: 10px;
  margin-right: 60px;
  width: 115px;
}

.description span {
  display: block;
  font-size: 14px;
  color: #e1523e;
  font-weight: 400;
}

.description span:first-child {
  margin-bottom: 5px;
}
.description span:last-child {
  font-weight: 300;
  color: #e1523e;
}
.quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.quantity input {
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}

button[class*="btn"] {
  width: 30px;
  height: 30px;
  background-color: #e1e8ee;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-top: 2px;
}

button:focus,
input:focus {
  outline: 0;
}
.cart-items .quantity input {
  width: 53px;
}
.procced-tocheckout {
  display: flex;
  margin-top: 4%;
  justify-content: center;
}
.sub-total {
  width: 100vw;
}
.total-price {
  font-size: 16px;
  color: #e1523e;
  margin-top: 21px;
  font-weight: 300;
}
@media (max-width: 800px) {
  .shopping-cart {
    width: 100%;
    /* height: auto; */
    overflow: hidden;
  }
  .item {
    height: auto;
    /* flex-wrap: wrap; */
    justify-content: center;
  }
  .image img {
    width: 50%;
  }
  .image,
  .quantity,
  .description {
    width: 27%;
    text-align: center;
    margin: 6px 0;
  }
  .buttons {
    margin-right: 20px;
  }
}
.bottom-cart {
  position: absolute;
  bottom: 0;
}
.sub-total {
  padding: 0px 25px;

  width: 47em;
  border-bottom: solid 1px #0b4c45;
  margin-bottom: 0px;
}
span.subtotal-price {
  text-align: right;
  color: #e1523e;
  float: right;
  font-weight: bold;
}
.sub-total h4 {
  color: #e1523e;
  font-size: 20px;
  margin: 8px;
}

/* apply fonts family*/
span.menu_i_tx {
  font-family: "Adobe Caslon Pro";
  text-transform: lowercase;
}
.scarves-page .shop-now {
  font-family: "Adobe Caslon Pro" !important;
  /* font-weight: 700 !important; */
}

/* **************
    My Account

*******************/
.my-account-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.my-account-header h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.my-account-content {
  display: flex;
}

.my-account-sidebar {
  width: 25%;
  margin-right: 20px;
}

.my-account-sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.my-account-sidebar ul li {
  margin-bottom: 10px;
}

.my-account-sidebar ul li a {
  display: block;
  padding: 10px;
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
}

.my-account-sidebar ul li a:hover {
  background-color: #333;
  color: #fff;
}

.my-account-main {
  flex-grow: 1;
}

.my-account {
  height: 100vh;
}

/* animation */
.slide- {
  height: 100vh;
}
.slide-in {
  animation: slide-in 0.5s ease-out;
}
.slide-out {
  animation: slide-out 5s ease-out;
}
button.add-to-cart-btn {
  width: fit-content;
  color: #000 !important;
  padding: 5px 17px;
  background-color: #fff5e6;
  font-size: 22px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 60px;
  font-weight: 600;
  font-family: "Adobe Caslon Pro";
  padding: 5px 35px 5px 35px;
  border: #96694c solid;
}
button.add-to-cart-btn:hover {
  color: #e1523e !important;
  border: #e1523e solid;
}
.headerCenter img.logo {
  width: 111px;
}
img.add-cart-logo {
  width: 76px;
  margin-right: 10px;
}
ul.menu.left,
ul.menu.right {
  margin-top: 30px;
}

.play-page .footer-centerPart p {
  background-color: #fff5e6;
  font-size: 25px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  margin-right: -41%;
  height: fit-content;
  margin-top: 8%;
  font-weight: 600;
  font-family: "Adobe Caslon Pro";
  padding: 0 20px;
  border: #96694c solid;
}
.top-border {
  border-top: solid #ff8d6b;
}

@keyframes slide-in {
  0% {
    transform: translateX(30%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);

    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0.5;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.excerpt {
  margin-top: 100px;
  text-align: justify;
}
#page-height {
  overflow-y: auto;
}
/* loader */
.overlay-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.loader-container {
  width: 100%;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  background-image: url("../../assets/images/scarves-bg.png");
  /* background: rgba(0, 0, 0, 0.834); */
  z-index: -1;
}

.spinner {
  width: 64px;
  height: 64px;
  z-index: 999;
  border: 8px solid;
  border-color: #96694c transparent #96694c transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.shopping-cart .spinner {
  position: absolute;
  top: 50%;
  right: 47%;
}
.shopping-cart .overlay-loader {
  width: 768px;
  position: absolute;
  right: 1px;
  height: 100vh;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.center-form.slide-in {
  /* height: 600px; */
  overflow-y: auto;
}
a.checkout-btn {
  color: #000;
  border: solid 2px #96694c;
  padding: 10px;
  font-size: 13px;
  background-color: #fff5e6;
}
a.checkout-btn:hover {
  color: #e1523e;
  border: solid 2px #e1523e;
}
.delivery .checkbox-wrapper-37 .terms-label {
  justify-content: space-between;
  flex-direction: column;
}
.delivery .checkbox-wrapper-37 {
  justify-content: space-between;
}

.delivery .checkbox-wrapper-37 input[type="radio"] {
  display: none;
}

.delivery
  .checkbox-wrapper-37
  input[type="radio"]:checked
  + .terms-label
  .checkbox-box,
.delivery
  .checkbox-wrapper-37
  input[type="radio"]:checked
  + .terms-label
  .checkbox-tick {
  stroke-dashoffset: 0;
}

img.left-logo {
  display: none;
}
.invalid-feedback {
  font-size: 10px;
}
.extra-address-items {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.extra-address-items-qty {
  float: right;
}
.cart-extra-address-items-qty {
  color: #0b4c45;
  padding: 0 100px;
}
.checkout-loader-cantain {
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}
button.checkout-btns {
  height: 40px;
  width: 150px;
  /* padding: 12px 12px 12px 12px; */
  background: transparent;
  border: solid 2px #e1523e;
  margin-top: 15px;
  font-size: 10px;
  color: #e1523e;
}
button.checkout-btns:hover {
  background-color: #0b4c45;
  border: solid 2px #0b4c45;
  color: #fff;
}
.login-fileds-checkout {
  padding-right: 40px;
}

li.menu_i img {
  margin-left: -50px;
  width: 129px;
  margin-top: -40px;
}
.stockists-menu ul.menu.right li.menu_i img {
  display: none;
}
ul.menu.left li.menu_i img {
  margin-left: -31px;
  width: 129px;
  margin-top: -63px;
}
ul.menu.left li.menu_i img {
  display: none;
}
.stockists-menu ul.menu.left li.menu_i img {
  display: block;
}
.main-footer,
.main-header {
  z-index: 9;
}
#overlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  top: 0%;

  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0b4c45; /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.centered {
  position: absolute;
  top: 50%;
  z-index: 3;
  font-size: 6vw;
  font-family: "Adobe Caslon Pro";
  text-transform: uppercase;
  font-weight: bold;
  color: #976a4d;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-title-on-Img {
  color: #e1523e;
  font-size: 30px;
  position: relative;
  overflow: auto;
  text-transform: uppercase;
  bottom: -80px;
  left: 44px;
  height: fit-content;
  display: block;
  font-weight: 600;
  font-family: "Adobe Caslon Pro";
  letter-spacing: 4px;
  /* -webkit-text-stroke-width: 2px; */
  /* -webkit-text-stroke-color: #E1523E;
  -webkit-text-fill-color: #96694c; */
}
label.form-label {
  font-size: 13px;
}
.discover-text {
  color: #976a4d;
  font-family: \ Adobe Caslon Pro;
  transform: rotate(180deg);
  text-transform: uppercase;
  font-size: 54px;
  letter-spacing: 0;
  word-spacing: 20px;
  writing-mode: vertical-rl;
  margin-left: auto;
  margin-top: 70%;
}
.product-title-details-page {
  color: #e1523e;
  font-family: "Rosarivo";
  font-size: 31px;
  text-transform: uppercase;
  padding: 0 0 0 7%;
  position: relative;
  bottom: -18px;
}
a.left.carousel-control {
  margin-top: 350px;
  margin-left: -7px;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 15;
}
a.right.carousel-control {
  margin-top: 350px;
  margin-right: -15px;
  height: fit-content;
}
.carousel-control {
  opacity: 1 !important;
}
.carousel-control:focus,
.carousel-control:hover,
.carousel-control.right {
  background: none !important;
}
.col-md-11.center-page {
  overflow-x: hidden !important;
}
ul.menu.right {
  margin-top: -100px;
}
ul.menu.right .menu_i {
  padding-bottom: 50px;
  margin: 20px 0 0;
}
.contiune-shoping {
  text-align: left;
  position: absolute;
  right: 105px;
  top: 0;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
}
/* .muteUnmuteIcon {
  margin-top: 1%;
} */
.muteUnmuteIcon img {
  width: 25px;
  cursor: pointer;
}
/* .muteUnmuteIcon img {
  position: relative;
  cursor: pointer;
  width: 30px;
  top: 3%;
  bottom: 3%;
  z-index: 8;
  right: 3%;
  left: 3%;
} */

.notification-success {
  background: #14756c !important;
}
.notification-error {
  background: #96694c !important;
}
.notification-success:before {
  content: "" !important;
}
.notification-error:before {
  content: "" !important;
}
.notification {
  padding: 15px 15px 15px 25px !important;
}

.toast-message {
  background: #14756c;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.ConfirmToast-module_title__LvF-m {
  color: #0b4c45 !important;
}
.ConfirmToast-module_container__HJqtg {
  background: #fff !important;
}
.ConfirmToast-module_close__VIyNv {
  color: #96694c !important;
}
.ConfirmToast-module_button__CkwoM.ConfirmToast-module_button_yes__3iwcr {
  background: #0b4c45 !important;
  color: #fff !important;
}
.ConfirmToast-module_button__CkwoM.ConfirmToast-module_button_no__fwzkG {
  background: #96694c !important;
  color: #fff !important;
  padding: 10px 12px;
}
.myaccount-btn-group {
  display: flex;
  justify-content: center;
}
 
.textnofoundPage {
  font-size: 8vw;
}

.height-page.no-found {
  min-height: 768px;
}
.height-page.no-found .slide-in.row {
  justify-content: center;
  flex-direction: column;
}
.sub-mobile-heading {
  display: none;
}

.why-page strong {
  color: #787878 !important;
}
.why-page a strong {
  color: rgb(151, 106, 77) !important;
}
.play-name-title input {
  padding-left: 15px;
}
.play-name-title input:focus {
  border: #e1523e solid 2px;
  padding-left: 15px;
}
.landing-page-icons {
  position: absolute;
  top: 0%;
  left: 55px;
  z-index: 10;
  padding: 4.5% 3% 0% 3%;
}
.landing-icon-enterSite {
  padding: 4.5% 3% 0% 3%;
  position: absolute;
  right: 3%;

  width: 100%;
}
.enterSiteBtn {
  width: 100px !important;
  float: right;
}
.text-made-you {
  position: relative;
}
.playpage-heading {
  color: #96694c;
  font-weight: 900;
  /* left: 5%; */
  /* position: absolute; */
  width: -moz-fit-content;
  /* width: fit-content; */
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  /* top: 15%; */
  margin-left: 27%;
}
.playpage-heading h1 {
  font-size: 4.5vw;
  font-weight: bold;
}
.play-page .product-play-img {
  width: 937px;
}
img.image.playattribute {
  margin-right: 0 !important;
  width: 90%;
}
.jirApZ {
  grid-gap: 2%;
}
.play-page .leftSide-center-text {
  margin-top: auto;
  margin-bottom: auto;
}
.checkout-guest {
  text-align: left;
  position: absolute;
  left: 53%;
  top: 0;
}
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
  display: none !important;
}
.Toastify__progress-bar--info {
  background: #e1523e !important
}
.Toastify__progress-bar--error {
  background: #e1523e !important
}
.content-bottom-logo .img-bottom-logo{
  width: 85px;
}
.content-bottom-logo {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.play-top-temp-text {
  color: #976a4d;
  z-index: 2;
  position: relative;
  font-size: 23px;
  padding-left: 7%;
  top: 100px;
  padding-right: 7% ;
  text-align: center;
  width: 100%;
}
.NavParent{
  height: 10%;
}
button.btn.btn-danger.view-myacc-bnt {
  width: 120px !important;
}
button.btn.btn-danger.view-myacc-bnt.updateBtn.updatepassword {
  width: fit-content !important;
}
