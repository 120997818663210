/* hide from desktop */

.mobile-logo,
.PageTitleMobile,
.PageTitleMobileScarves,
.mobileScreen,.content-bottom-logo .img-bottom-logo {
  display: none;
}

@media (min-width: 300px) and (max-width: 374px) {
  .mobileScreen {
    display: block !important;
  }
  .desktopScreen {
    display: none !important;
  }
  .copyright-text {
    margin-left: -37px;
  }
  /* .slide- {
    height: 163vh;
} */
  /* Landing Page */
  .header.row,
  .footer.row,
  .hideOnMobile,
  .sub-heading-Mainpage {
    display: none;
  }
  .sub-mobile-heading {
    display: block;
  }
  .muteUnmuteIcon img {
    left: 17px;
  }
  .greenLogo img {
    width: 50%;
    bottom: 70px !important;
    left: 80px !important;
  }
  .mobile-logo img {
    width: 100px;
    margin-top: 20px;
  }
  .mobile-logo {
    display: block;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* Header */
  .menuIcon {
    width: 20px;
  }
  .main-headerLeft {
    padding: 0% 0% 0% 25px !important;
  }
  img.logo {
    width: 28%;
    margin-top: -19px;
    margin-left: 50px;
    margin-bottom: -19px;
  }
  .accountIcon {
    margin-right: -30px !important;
  }
  div.searchForm {
    width: 112px;
    right: 40px !important;
    margin-top: -50px;
  }
  div.searchForm input {
    width: 80px;
    font-size: 10px;
    color: #ff8d6b;
    border-bottom: #ff8d6b solid 1px;
    float: right;
    margin-right: 12px;
  }
  img.cart-icon {
    width: 22px;
    margin-left: 2px;
    float: right;
    margin-bottom: 0;
  }
  .Search_result-content {
    top: 20px;
    width: 130%;
  }
  .overlay a {
    font-size: 10px !important;
  }
  .overlay .menuitem a {
    border-bottom: #ff8d6b solid 1px;
  }
  /* footer */
  .main-footer.row .footer-Logo {
    width: 60px;
    margin-left: 4px;
  }
  .footer-centerPart p {
    font-size: 11px;
    margin-left: -24px;
    margin-top: 24px;
    border: #96694c solid 1px;
    margin-bottom: 12px;
  }
  .col-footer-1 {
    margin-left: 50px;
  }
  .copyright-text p {
    font-size: 7px;
  }
  .social-icons img {
    width: 14px;
  }
  .social-icons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  /* home page */
  .home-page .sub-heading {
    font-size: 1rem;
    top: 68%;
    left: -47%;
  }
  .home-page .right {
    background-size: auto;
    order: 0;
  }
  .home-page .left {
    order: 1;
    transform: scaleX(-1);
    background-size: auto;
  }
  .home-page {
    min-height: 768px !important;
  }
  /* sideBarMenu */
  .leftMobileMenu,
  .rightMobileMenu {
    visibility: hidden;
    transition: visibility 1s, opacity 0.1s linear;
    opacity: 0;
  }
  .leftMobileMenu .menu.left,
  .rightMobileMenu .menu.right {
    background: #0b4c45;
  }
  ul.menu.left,
  ul.menu.right {
    top: 0px !important;
  }
  html,
  body {
    height: 100%;
  }
  div#rightMobileMenu,
  div#leftMobileMenu {
    position: relative;
    min-height: 100% !important;
  }
  .menu.left {
    padding: 2% 0% 17% 0%;
  }
  ul.menu.left,
  ul.menu.right {
    margin-top: 0px;
  }
  li.menu_i {
    border-bottom: solid 1px #96694c;
    width: 100px;
  }
  li.menu_i:last-child {
    border-bottom: none;
  }
  .menu_i_tx,
  .right .menu_i_tx {
    font-size: 3rem;
  }
  .nav-link {
    padding: 0.5rem 1rem 0.5rem 2rem;
  }
  .menu.right {
    padding: 2% 0% 45% 0%;
  }
  .right .menu_i_tx {
    transform: translate(0, 0) rotate(90deg) !important;
    margin-left: 65px;
    -webkit-text-stroke-color: #e1523e !important;
  }
  #page-heights {
    overflow-y: auto;
    height: 200px;
  }
  /* Why Page */
  p#page-heights {
    text-align: left;
    /* font-size: 15px; */
    margin-top: 0;
    /* line-height: 18px; */
  }
  .whypage-center-text {
    padding: 0% 7% 7%;
  }
  .excerpt {
    margin-top: 50px;
    text-align: justify;
  }
  .whypage-sub-heading {
    color: #808080;
    font-family: "Fira Sans";
    font-size: 11px;
    line-height: 20px;
  }
  .leftSide-center-text p,
  .wp-block-heading {
    color: #00000099;
  }
  .top-border {
    border-bottom: none;
    border-top: none;
  }
  .plus-icon {
    float: right;
    margin-top: -6%;
    cursor: pointer;
    margin-bottom: 20px;
  }
  h1.whypage-heading {
    font-weight: 500;
  }
  .content-pages-heading {
    position: relative;
  }
  .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 70px;
    left: 0;
  }
  /* process page */
  .process-right {
    height: 360px;
  }
  /* .process-right .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 198px;
    left: -38px;
  } */
  /* Scarves Page */
  .product-title-on-Img {
    font-size: 15px !important;
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }
  .discover-text {
    display: none;
  }
  .scrves-page-mobile-1 {
    width: 0% !important;
  }
  .scrves-page-mobile-2 {
    width: 100% !important;
  }
  .scarves-page .product-img {
    height: 270px !important;
  }
  .scarves-page .shop-now {
    border: #96694c solid 1px;
    padding: 5px 15px;
  }
  .scarves-page .shop-now:hover {
    color: #e1523e;
    background: #0b4c45;
    border: none;
  }
  .scarves-page .center-page {
    height: 100% !important;
    min-height: 768px !important;
  }
  .scarves-page .shop-now {
    font-size: 13px;
  }
  .PageTitleMobileScarves {
    display: block;
    position: absolute;
    color: #e1523e;
    right: 0;
    font-weight: 600;
    font-size: 40px;
    transform: rotate(90deg);
    top: 14%;
  }
  /* scarves Detail Page */
  div#myCarousel {
    margin-top: 20px;
  }
  .item {
    padding: 0 10px 0 20px !important;
  }
  .slider-text {
    margin-top: 0px;
  }
  .carousel-indicators {
    margin-bottom: -2rem;
  }
  .carousel-indicators li {
    margin: 20px 15px 0px 0px;
  }
  .buy-text-after-attribute {
    display: none;
  }
  .product-price-slider {
    margin-top: 80px;
    font-size: 15px;
  }
  .buy-text-after-slider a {
    font-size: 15px;
  }
  a.right.carousel-control {
    margin-top: 160px;
    margin-right: -23px;
  }
  a.left.carousel-control {
    margin-top: 160px;
    margin-left: -12px;
  }
  .carousel-control {
    opacity: 1;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .buy-text-after-slider {
    margin-right: 10px;
  }
  #progressbar li:after {
    top: 9px !important;
  }
  /* My Account Page */
  .react-tabs {
    width: 100%;
    border: none;
    margin-top: 5px;
  }
  .react-tabs__tab--selected {
    padding: 11px 13px !important;
    font-size: 12px;
  }
  .react-tabs__tab {
    font-size: 12px;
    padding: 11px 13px !important;
  }
  button.btn.btn-danger.view-myacc-bnt {
    width: 100px !important;
    font-size: 10px;
    padding-bottom: 1px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .Billing-address-box .thank-you-page {
    font-size: 12px;
  }
  fieldset.myaccount-fieldset {
    padding: 0 20px;
    margin-top: 5px;
  }
  button.btn.btn-danger.view-myacc-bnt.updateBtn {
    width: 100% !important;
  }
  .order-table {
    font-size: 12px;
  }
  .myaccount-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sub-mobile-heading {
    font-size: 6vw;
    font-weight: bold;
    font-style: italic;
    position: relative;
    display: flex;
    color: #df8466;
    justify-content: center;
    font-family: "Adobe Caslon Pro";
    top: 83%;
  }
  .sub-mobile-heading:hover {
    color: #df8466;
  }
  .product-title-details-page {
    padding: 0 0 0 11%;
    font-size: 7vw;
  }
  .shopping-cart {
    width: 321px !important;
  }
  .cart-items .item .image img {
    margin-top: 10px;
    margin-left: -10px;
    width: 43px;
  }
  .cart-items .item .image {
    width: 43px;
    height: fit-content;
  }
  .cart-items .item .description {
    width: 55px;
    margin-top: 18px;
  }
  .cart-items .description span {
    font-size: 11px;
  }
  .cart-items .quantity {
    width: fit-content;
    margin-left: 10px;
  }
  .cart-items .quantity input {
    width: 30px;
    font-size: 12px;
  }
  .total-price {
    font-size: 11px;
    margin-left: 25px;
  }
  button[class*="btn"] {
    width: 25px;
    height: 25px;
  }
  .plus-btn img {
    margin-top: -2px;
  }
  a.checkout-btn {
    font-size: 11px;
  }
  .cart-items {
    /* overflow: hidden; */
  }
  .sub-total h4 {
    color: #e1523e;
    font-size: 13px;
  }
  .sub-total {
    width: 320px;
  }
  .contiune-shoping div {
    margin-right: -50px !important;
  }
  .checkout-page .left-side input.form-control,
  .checkout-page .left-side select.form-control {
    height: 50px;
    margin-top: 15px;
  }
  #msform input,
  #msform textarea,
  #msform select {
    font-size: 9px !important;
  }
  button.checkout-btns {
    width: fit-content;
  }
  .checkout-page .left-side {
    padding: 2rem 0rem 2rem 0.3rem !important;
  }
  button.checkout-btns {
    padding: 11px 40px 22px 40px !important;
  }
  .StripeElement {
    margin-right: 5px !important;
  }
  .auth-page img.logo {
    margin-left: 0px;
  }
  .auth-page .main-heading {
    font-size: 25px;
  }
  #page-height-exerpt {
    font-size: 15px !important;
  }
  .home-page {
    min-height: 950px;
  }
  .play-page .product-img {
    width: 82%;
    height: 600px;
    object-fit: contain;
    margin-top: -100px;
  }
  .play-page .heading {
    font-size: 2rem;
    /* text-align: center; */
    padding-left: 10px;
  }
  h3.attribute-heading,
  .play-page .heading,
  .price span,
  .play-name-title,
  .add-to-cart-area {
    margin-left: 0px;
  }
  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 10px;
  }
  .play-page .right-side {
    margin-top: 5%;
    padding-left: 10px;
  }
  .attribute-main {
    margin-left: 0px;
  }
  #scarfs .etbmwA {
    display: block !important;
  }
  .arrow-right {
    transform: rotate(180deg);
    float: right;
    position: absolute;
    top: 63px;
    right: 5px;
    cursor: pointer;
    width: 12px;
  }
  .gqzvZt {
    grid-template-columns: repeat(5, 22%) !important;
    grid-template-rows: 1fr !important;
  }
  .attribute-main .image {
    width: 54px;
  }
  h3.attribute-heading {
    font-size: 0.7rem;
    font-weight: 400;
  }
  h3.attribute-heading {
    margin-top: 10px;
  }
  .play-page .right-side {
    margin-top: 0;
    padding-left: 10px;
  }
  button.add-to-cart-btn {
    width: fit-content;
    height: fit-content;
  }
  .play-name-title input,
  .play-email input,
  .play-page .input-felid {
    width: 98%;
    height: 45px;
    font-size: 10px;
  }
  .price span {
    font-size: 16px;
    position: relative;
    left: 1px;
    color: #e1523e;
  }
  button.checkout-btns {
    font-size: 7px !important;
  }
  .main-footer {
    height: 6rem !important;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
    padding-bottom: 5.99rem;
  }
  .playpage-heading {
    color: #96694c;
    font-weight: 900;
    width: -moz-fit-content;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin-left: 5%;
  }
  .text-on-image {
    font-size: 1.6vw;
    line-height: 15px;
  }
  .play-page .product-play-img {
    margin: 0 10px;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  #msform fieldset {
    padding: 0;
  }
  .shipping-top-heading {
    padding-left: 0px;
  }
  #progressbar {
    padding-left: 0 !important;
  }
  .checkout-page .main-heading {
    font-size: 1.7rem;
    left: -13%;
    top: -30px;
  }
  .checkout-page .progressbar-items {
    font-size: 0.5rem;
  }
  #progressbar li:before {
    margin-top: 2px;
    width: 17px;
    height: 17px;
  }

  .slider-text p {
    padding: 0 6px;
  }
}

@media (min-width: 375px) and (max-width: 400px) {
  .mobileScreen {
    display: block !important;
  }
  .desktopScreen {
    display: none !important;
  }
  /* Landing Page */
  .header.row,
  .footer.row,
  .hideOnMobile,
  .sub-heading-Mainpage {
    display: none;
  }
  .sub-mobile-heading {
    display: block;
  }
  .muteUnmuteIcon img {
    left: 17px;
  }
  .greenLogo img {
    width: 50%;
    bottom: 70px !important;
    left: 80px !important;
  }
  .mobile-logo img {
    width: 100px;
    margin-top: 20px;
  }
  .mobile-logo {
    display: block;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* Header */
  .menuIcon {
    width: 20px;
  }
  .main-headerLeft {
    padding: 0% 0% 0% 25px !important;
  }
  img.logo {
    width: 28%;
    margin-top: -19px;
    margin-left: 85px;
    margin-bottom: -19px;
  }
  div.searchForm {
    width: 112px;
    right: 65px !important;
    margin-top: -60px;
  }
  div.searchForm input {
    width: 90px;
    font-size: 10px;
    color: #ff8d6b;
    border-bottom: #ff8d6b solid 1px;
    float: right;
    margin-right: 12px;
  }
  img.cart-icon {
    width: 22px;
    margin-left: 10px;
    float: right;
    margin-bottom: 0;
  }
  .Search_result-content {
    top: 40px;
    width: 130%;
    left: 0;
  }
  .overlay a {
    font-size: 10px !important;
  }
  .overlay .menuitem a {
    border-bottom: #ff8d6b solid 1px;
  }
  /* footer */
  .main-footer.row .footer-Logo {
    width: 60px;
    margin-left: 10px;
  }
  .footer-centerPart p {
    font-size: 11px;
    margin-left: -24px;
    margin-top: 24px;
    border: #96694c solid 1px;
    margin-bottom: 12px;
  }
  .col-footer-1 {
    margin-left: 50px;
  }
  .copyright-text p {
    font-size: 6px;
    margin-left: -23px;
  }
  .social-icons img {
    width: 14px;
  }
  .social-icons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  /* home page */
  .home-page .sub-heading {
    font-size: 1rem;
    top: 68%;
    left: -47%;
  }
  .home-page .right {
    background-size: auto;
    order: 0;
  }
  .home-page .left {
    order: 1;
    transform: scaleX(-1);
    background-size: auto;
  }
  /* sideBarMenu */
  .leftMobileMenu,
  .rightMobileMenu {
    visibility: hidden;
    transition: visibility 1s, opacity 0.1s linear;
    opacity: 0;
    height: 100%;
    position: relative;
  }
  .leftMobileMenu .menu.left,
  .rightMobileMenu .menu.right {
    background: #0b4c45;
  }
  ul.menu.left,
  ul.menu.right {
    top: 0px !important;
  }
  .menu.left {
    padding: 2% 0% 15% 0%;
  }
  ul.menu.left,
  ul.menu.right {
    margin-top: 0px;
  }
  li.menu_i {
    border-bottom: solid 1px #96694c;
    width: 100px;
  }
  li.menu_i:last-child {
    border-bottom: none;
  }
  .menu_i_tx,
  .right .menu_i_tx {
    font-size: 3rem;
  }
  .nav-link {
    padding: 0.5rem 1rem 0.5rem 2rem;
  }
  .menu.right {
    padding: 2% 0% 40% 0%;
  }
  .right .menu_i_tx {
    transform: translate(0, 0) rotate(90deg) !important;
    margin-left: 65px;
    -webkit-text-stroke-color: #e1523e !important;
  }
  #page-heights {
    overflow-y: auto;
    height: 200px;
  }
  /* Why Page */
  p#page-heights {
    text-align: left;
    /* font-size: 15px; */
    margin-top: 0;
    /* line-height: 18px; */
  }
  .whypage-center-text {
    padding: 0% 7% 7%;
  }
  .excerpt {
    margin-top: 50px;
    text-align: justify;
  }
  .whypage-sub-heading {
    color: #808080;
    font-family: "Fira Sans";
    font-size: 11px;
    line-height: 20px;
  }
  .leftSide-center-text p,
  .wp-block-heading {
    color: #00000099;
  }
  .top-border {
    border-bottom: none;
    border-top: none;
  }
  .plus-icon {
    float: right;
    margin-top: -3%;
    cursor: pointer;
    margin-bottom: 20px;
  }
  h1.whypage-heading {
    font-weight: 500;
  }
  .content-pages-heading {
    position: relative;
  }
  .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 70px;
    left: 0;
  }
  /* process page */
  .process-right {
    height: 360px;
  }
  /* .process-right .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 198px;
    left: -38px;
  } */
  /* Scarves Page */
  .product-title-on-Img {
    font-size: 15px !important;
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }
  .discover-text {
    display: none;
  }
  .scrves-page-mobile-1 {
    width: 0% !important;
  }
  .scrves-page-mobile-2 {
    width: 100% !important;
  }
  .scarves-page .product-img {
    height: 270px !important;
  }
  .scarves-page .shop-now {
    border: #96694c solid 1px;
    padding: 5px 15px;
  }
  .scarves-page .shop-now:hover {
    color: #e1523e;
    background: #0b4c45;
    border: none;
  }
  .scarves-page .center-page {
    height: 100% !important;
    min-height: 768px !important;
  }
  .scarves-page .shop-now {
    font-size: 13px;
  }
  .PageTitleMobileScarves {
    display: block;
    position: absolute;
    color: #e1523e;
    right: 0;
    font-weight: 600;
    font-size: 40px;
    transform: rotate(90deg);
    top: 14%;
  }
  /* scarves Detail Page */
  div#myCarousel {
    margin-top: 20px;
  }
  .item {
    padding: 0 10px 0 20px !important;
  }
  .slider-text {
    margin-top: 0px;
  }
  .carousel-indicators {
    margin-bottom: -2rem;
  }
  .carousel-indicators li {
    margin: 20px 15px 0px 0px;
  }
  .buy-text-after-attribute {
    display: none;
  }
  .product-price-slider {
    margin-top: 80px;
    font-size: 15px;
  }
  .buy-text-after-slider a {
    font-size: 15px;
  }
  a.right.carousel-control {
    margin-top: 160px;
    margin-right: -23px;
  }
  a.left.carousel-control {
    margin-top: 160px;
    margin-left: -12px;
  }
  .carousel-control {
    opacity: 1;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .buy-text-after-slider {
    margin-right: 10px;
  }
  /* checkoutpage */
  #progressbar li:after {
    top: 9px !important;
  }
  #progressbar li {
    width: 89px !important;
  }
  /* My Account Page */
  .react-tabs {
    width: 100%;
    border: none;
    margin-top: 5px;
  }
  .react-tabs__tab--selected {
    padding: 11px 17px !important;
    font-size: 12px;
  }
  .react-tabs__tab {
    font-size: 12px;
    padding: 11px 17px !important;
  }
  button.btn.btn-danger.view-myacc-bnt {
    width: 100px !important;
    font-size: 10px;
    padding-bottom: 1px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .Billing-address-box .thank-you-page {
    font-size: 12px;
  }
  fieldset.myaccount-fieldset {
    padding: 0 20px;
    margin-top: 5px;
  }
  button.btn.btn-danger.view-myacc-bnt.updateBtn {
    width: 100% !important;
  }
  .order-table {
    font-size: 12px;
  }
  .myaccount-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .sub-mobile-heading {
    font-size: 6vw;
    font-weight: bold;
    font-style: italic;
    position: relative;
    display: flex;
    color: #df8466;
    justify-content: center;
    font-family: "Adobe Caslon Pro";
    top: 83%;
  }
  .sub-mobile-heading:hover {
    color: #df8466;
  }
  .product-title-details-page {
    padding: 0 0 0 11%;
    font-size: 7vw;
  }
  /* cart */
  .shopping-cart {
    width: 350px !important;
  }
  .cart-items .item .image img {
    margin-top: 10px;
    margin-left: -10px;
    width: 43px;
  }
  .cart-items .item .image {
    width: 43px;
    height: fit-content;
  }
  .cart-items .item .description {
    width: 55px;
    margin-top: 18px;
  }
  .cart-items .description span {
    font-size: 11px;
  }
  .cart-items .quantity {
    width: fit-content;
    margin-left: 40px;
  }
  .cart-items .quantity input {
    width: 30px;
    font-size: 12px;
  }
  .total-price {
    font-size: 11px;
    margin-left: 25px;
  }
  button[class*="btn"] {
    width: 25px;
    height: 25px;
  }
  .plus-btn img {
    margin-top: -2px;
  }
  a.checkout-btn {
    font-size: 11px;
  }
  .cart-items {
    /* overflow: hidden; */
  }
  .sub-total h4 {
    color: #e1523e;
    font-size: 13px;
  }
  .sub-total {
    width: 349px !important;
  }
  .contiune-shoping div {
    margin-right: -30px !important;
    }
  .checkout-page .left-side input.form-control,
  .checkout-page .left-side select.form-control {
    height: 50px;
    margin-top: 15px;
  }
  #msform input,
  #msform textarea,
  #msform select {
    font-size: 10px !important;
  }
  button.checkout-btns {
    width: fit-content;
  }
  .checkout-page .left-side {
    padding: 2rem 0rem 2rem 0.3rem !important;
  }
  button.checkout-btns {
    padding: 11px 40px 22px 40px !important;
  }
  .StripeElement {
    margin-right: 5px !important;
  }
  .auth-page img.logo {
    margin-left: 0px;
  }
  #page-height-exerpt {
    font-size: 15px !important;
  }
  .home-page {
    min-height: 777px;
  }
  /* play page */
  .play-page .product-img {
    width: 80%;
    height: 600px;
    object-fit: contain;
    margin-top: -60px;
  }
  .play-page .heading {
    font-size: 2rem;
    /* text-align: center; */
    padding-left: 10px;
  }
  h3.attribute-heading,
  .play-page .heading,
  .price span,
  .play-name-title,
  .add-to-cart-area {
    margin-left: 0px;
  }
  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 10px;
  }
  .play-page .right-side {
    margin-top: 5%;
    padding-left: 10px;
  }
  .attribute-main {
    margin-left: 0px;
  }
  #scarfs .etbmwA {
    display: block !important;
  }
  .arrow-right {
    transform: rotate(180deg);
    float: right;
    position: absolute;
    top: 63px;
    right: 5px;
    cursor: pointer;
    width: 12px;
  }
  .gqzvZt {
    grid-template-columns: repeat(5, 22%) !important;
    grid-template-rows: 1fr !important;
  }
  .attribute-main .image {
    width: 54px;
  }
  h3.attribute-heading {
    font-size: 0.7rem;
    font-weight: 400;
  }
  h3.attribute-heading {
    margin-top: 10px;
  }
  .play-page .right-side {
    margin-top: 0;
    padding-left: 10px;
  }
  button.add-to-cart-btn {
    width: fit-content;
    height: fit-content;
  }
  .play-name-title input,
  .play-email input,
  .play-page .input-felid {
    width: 98%;
    height: 45px;
    font-size: 10px;
  }
  .price span {
    font-size: 16px;
    position: relative;
    left: 1px;
    color: #e1523e;
  }
  /* .slide- {
    height: 163vh;
} */
  .main-footer {
    height: 6rem !important;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
    padding-bottom: 5.99rem;
  }
  .playpage-heading {
    color: #96694c;
    font-weight: 900;
    width: -moz-fit-content;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin-left: 5%;
  }
  .text-on-image {
    font-size: 1.6vw;
    line-height: 15px;
  }
  .play-page .product-play-img {
    margin: 0 10px;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  #msform fieldset {
    padding: 0;
  }
  .shipping-top-heading {
    padding-left: 0px;
  }
  #progressbar {
    padding-left: 0 !important;
  }
  .checkout-page .main-heading {
    font-size: 1.7rem;
    left: -10%;
    top: -30px;
  }
  .checkout-page .progressbar-items {
    font-size: 0.5rem;
  }
  #progressbar li:before {
    margin-top: 2px;
    width: 17px;
    height: 17px;
  }
  .slider-text p {
    padding: 0 6px;
  }
}

@media (min-width: 401px) and (max-width: 425px) {
  .mobileScreen {
    display: block !important;
  }
  .desktopScreen {
    display: none !important;
  }
  /* Landing Page */
  .header.row,
  .footer.row,
  .hideOnMobile,
  .sub-heading-Mainpage {
    display: none;
  }
  .sub-mobile-heading {
    display: block;
  }
  .muteUnmuteIcon img {
    left: 17px;
  }
  .greenLogo img {
    width: 50%;
    bottom: 70px !important;
    left: 80px !important;
  }
  .mobile-logo img {
    width: 100px;
    margin-top: 20px;
  }
  .mobile-logo {
    display: block;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* Header */
  .menuIcon {
    width: 20px;
  }
  .main-headerLeft {
    padding: 0% 0% 0% 25px !important;
  }
  img.logo {
    width: 28%;
    margin-top: -19px;
    margin-left: 105px;
    margin-bottom: -19px;
  }
  div.searchForm {
    width: 112px;
    right: 60px !important;
    margin-top: -70px;
  }
  div.searchForm input {
    width: 90px;
    font-size: 10px;
    color: #ff8d6b;
    border-bottom: #ff8d6b solid 1px;
    float: right;
    margin-right: 12px;
  }
  img.cart-icon {
    width: 22px;
    margin-left: 10px;
    float: right;
    margin-bottom: 0;
  }
  .Search_result-content {
    top: 40px;
    width: 130%;
    left: 0;
  }
  .overlay a {
    font-size: 10px !important;
  }
  .overlay .menuitem a {
    border-bottom: #ff8d6b solid 1px;
  }
  /* footer */
  .main-footer.row .footer-Logo {
    width: 60px;
    margin-left: 20px;
  }
  .footer-centerPart p {
    font-size: 11px;
    margin-left: -25px;
    margin-top: 20px;
    border: #96694c solid 1px;
  }
  .col-footer-1 {
    margin-left: 50px;
  }
  .copyright-text p {
    font-size: 6px;
    margin-left: -52px;
  }
  .social-icons img {
    width: 14px;
  }
  .social-icons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  /* home page */
  .home-page .sub-heading {
    font-size: 1rem;
    top: 68%;
    left: -47%;
  }
  .home-page .right {
    background-size: auto;
    order: 0;
  }
  .home-page .left {
    order: 1;
    transform: scaleX(-1);
    background-size: auto;
  }
  .home-page {
    min-height: 820px;
  }
  /* sideBarMenu */
  .leftMobileMenu,
  .rightMobileMenu {
    visibility: hidden;
    transition: visibility 1s, opacity 0.1s linear;
    opacity: 0;
    height: 100%;
    position: relative;
  }
  .leftMobileMenu .menu.left,
  .rightMobileMenu .menu.right {
    background: #0b4c45;
  }
  ul.menu.left,
  ul.menu.right {
    top: 0px !important;
  }
  .menu.left {
    padding: 2% 0% 6% 0%;
  }
  ul.menu.left,
  ul.menu.right {
    margin-top: 0px;
  }
  li.menu_i {
    border-bottom: solid 1px #96694c;
    width: 100px;
  }
  li.menu_i:last-child {
    border-bottom: none;
  }
  .menu_i_tx,
  .right .menu_i_tx {
    font-size: 3rem;
  }
  .nav-link {
    padding: 0.5rem 1rem 0.5rem 2rem;
  }
  .menu.right {
    padding: 2% 0% 27% 0%;
  }
  .right .menu_i_tx {
    transform: translate(0, 0) rotate(90deg) !important;
    margin-left: 65px;
    -webkit-text-stroke-color: #e1523e !important;
  }
  #page-heights {
    overflow-y: auto;
    height: 200px;
  }
  /* Why Page */
  p#page-heights {
    text-align: left;
    /* font-size: 15px; */
    margin-top: 0;
    /* line-height: 18px; */
  }
  .whypage-center-text {
    padding: 0% 7% 7%;
  }
  .excerpt {
    margin-top: 50px;
    text-align: justify;
  }
  .whypage-sub-heading {
    color: #808080;
    font-family: "Fira Sans";
    font-size: 11px;
    line-height: 20px;
  }
  .leftSide-center-text p,
  .wp-block-heading {
    color: #00000099;
  }
  .top-border {
    border-bottom: none;
    border-top: none;
  }
  .plus-icon {
    float: right;
    margin-top: -3%;
    cursor: pointer;
    margin-bottom: 20px;
  }
  h1.whypage-heading {
    font-weight: 500;
  }
  .content-pages-heading {
    position: relative;
  }
  .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 70px;
    left: 0;
  }
  /* process page */
  .process-right {
    height: 360px;
  }
  /* .process-right .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 198px;
    left: -38px;
  } */
  /* Scarves Page */
  .product-title-on-Img {
    font-size: 15px !important;
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }
  .discover-text {
    display: none;
  }
  .scrves-page-mobile-1 {
    width: 0% !important;
  }
  .scrves-page-mobile-2 {
    width: 100% !important;
  }
  .scarves-page .product-img {
    height: 270px !important;
  }
  .scarves-page .shop-now {
    border: #96694c solid 1px;
    padding: 5px 15px;
  }
  .scarves-page .shop-now:hover {
    color: #e1523e;
    background: #0b4c45;
    border: none;
  }
  .scarves-page .center-page {
    height: 100% !important;
    min-height: 768px !important;
  }
  .scarves-page .shop-now {
    font-size: 13px;
  }
  .PageTitleMobileScarves {
    display: block;
    position: absolute;
    color: #e1523e;
    right: 0;
    font-weight: 600;
    font-size: 40px;
    transform: rotate(90deg);
    top: 14%;
  }
  /* scarves Detail Page */
  div#myCarousel {
    margin-top: 20px;
  }
  .item {
    padding: 0 10px 0 20px !important;
  }
  .slider-text {
    margin-top: 0px;
  }
  .carousel-indicators {
    margin-bottom: -2rem;
  }
  .carousel-indicators li {
    margin: 20px 15px 0px 0px;
  }
  .buy-text-after-attribute {
    display: none;
  }
  .product-price-slider {
    margin-top: 80px;
    font-size: 15px;
  }
  .buy-text-after-slider a {
    font-size: 15px;
  }
  a.right.carousel-control {
    margin-top: 160px;
    margin-right: -23px;
  }
  a.left.carousel-control {
    margin-top: 160px;
    margin-left: -12px;
  }
  .carousel-control {
    opacity: 1;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .buy-text-after-slider {
    margin-right: 10px;
  }
  /* checkout */
  #progressbar li:after {
    top: 9px !important;
  }
  /* My Account Page */
  .react-tabs {
    width: 100%;
    border: none;
    margin-top: 5px;
  }
  .react-tabs__tab--selected {
    padding-left: 3px;
    font-size: 12px;
  }
  .react-tabs__tab {
    font-size: 12px;
  }
  button.btn.btn-danger.view-myacc-bnt {
    width: 100px !important;
    font-size: 10px;
    padding-bottom: 1px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .Billing-address-box .thank-you-page {
    font-size: 12px;
  }
  fieldset.myaccount-fieldset {
    padding: 0 20px;
    margin-top: 5px;
  }
  button.btn.btn-danger.view-myacc-bnt.updateBtn {
    width: 100% !important;
  }
  .order-table {
    font-size: 12px;
  }
  .myaccount-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .sub-mobile-heading {
    font-size: 6vw;
    font-weight: bold;
    font-style: italic;
    position: relative;
    display: flex;
    color: #df8466;
    justify-content: center;
    font-family: "Adobe Caslon Pro";
    top: 83%;
  }
  .sub-mobile-heading:hover {
    color: #df8466;
  }
  .product-title-details-page {
    padding: 0 0 0 11%;
    font-size: 7vw;
  }
  .shopping-cart {
    width: 351px !important;
  }
  .cart-items .item .image img {
    margin-top: 10px;
    margin-left: -10px;
    width: 43px;
  }
  .cart-items .item .image {
    width: 43px;
    height: fit-content;
  }
  .cart-items .item .description {
    width: 55px;
    margin-top: 18px;
  }
  .cart-items .description span {
    font-size: 11px;
  }
  .cart-items .quantity {
    width: fit-content;
    margin-left: 40px;
  }
  .cart-items .quantity input {
    width: 30px;
    font-size: 12px;
  }
  .total-price {
    font-size: 11px;
    margin-left: 25px;
  }
  button[class*="btn"] {
    width: 25px;
    height: 25px;
  }
  .plus-btn img {
    margin-top: -2px;
  }
  a.checkout-btn {
    font-size: 11px;
  }
  .cart-items {
    /* overflow: hidden; */
  }
  .sub-total h4 {
    color: #e1523e;
    font-size: 13px;
  }
  .sub-total {
    width: 350px !important;
  }
  .contiune-shoping div {
    margin-right: 0px !important;
  }
  .checkout-page .left-side input.form-control,
  .checkout-page .left-side select.form-control {
    height: 50px;
    margin-top: 15px;
  }
  #msform input,
  #msform textarea,
  #msform select {
    font-size: 10px !important;
  }
  button.checkout-btns {
    width: fit-content;
  }
  .checkout-page .left-side {
    padding: 2rem 0rem 2rem 0.3rem !important;
  }
  button.checkout-btns {
    padding: 11px 40px 22px 40px !important;
  }
  .StripeElement {
    margin-right: 5px !important;
  }
  .auth-page img.logo {
    margin-left: 0px;
  }
  #page-height-exerpt {
    font-size: 15px !important;
  }
  .home-page {
    min-height: 768px;
  }
  /* play page */
  .play-page .product-img {
    width: 75%;
    height: 600px;
    object-fit: contain;
    margin-top: -60px;
  }
  .play-page .heading {
    font-size: 2rem;
    /* text-align: center; */
    padding-left: 10px;
  }
  h3.attribute-heading,
  .play-page .heading,
  .price span,
  .play-name-title,
  .add-to-cart-area {
    margin-left: 0px;
  }
  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 10px;
  }
  .play-page .right-side {
    margin-top: 5%;
    padding-left: 10px;
  }
  .attribute-main {
    margin-left: 0px;
  }
  #scarfs .etbmwA {
    display: block !important;
  }
  .arrow-right {
    transform: rotate(180deg);
    float: right;
    position: absolute;
    top: 63px;
    right: 5px;
    cursor: pointer;
    width: 12px;
  }
  .gqzvZt {
    grid-template-columns: repeat(5, 22%) !important;
    grid-template-rows: 1fr !important;
  }
  .attribute-main .image {
    width: 54px;
  }
  h3.attribute-heading {
    font-size: 0.7rem;
    font-weight: 400;
  }
  h3.attribute-heading {
    margin-top: 10px;
  }
  .play-page .right-side {
    margin-top: 0;
    padding-left: 10px;
  }
  button.add-to-cart-btn {
    width: fit-content;
    height: fit-content;
  }
  .play-name-title input,
  .play-email input,
  .play-page .input-felid {
    width: 98%;
    height: 45px;
    font-size: 10px;
  }
  .price span {
    font-size: 16px;
    position: relative;
    left: 1px;
    color: #e1523e;
  }
  /* .slide- {
    height: 163vh;
} */
  .main-footer {
    height: 6rem !important;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
    padding-bottom: 5.99rem;
  }
  .playpage-heading {
    color: #96694c;
    font-weight: 900;
    width: -moz-fit-content;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin-left: 5%;
  }
  .text-on-image {
    font-size: 1.6vw;
    line-height: 15px;
  }
  .play-page .product-play-img {
    margin: 0 10px;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  #msform fieldset {
    padding: 0;
  }
  .shipping-top-heading {
    padding-left: 0px;
  }
  #progressbar {
    padding-left: 0 !important;
  }
  .checkout-page .main-heading {
    font-size: 1.7rem;
    left: -9%;
    top: -30px;
  }
  .checkout-page .progressbar-items {
    font-size: 0.5rem;
  }
  #progressbar li:before {
    margin-top: 2px;
    width: 17px;
    height: 17px;
  }
  .slider-text p {
    padding: 0 6px;
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .mobileScreen {
    display: block !important;
  }
  .desktopScreen {
    display: none !important;
  }
  /* Landing Page */
  .header.row,
  .footer.row,
  .hideOnMobile,
  .sub-heading-Mainpage {
    display: none;
  }
  .sub-mobile-heading {
    display: block;
  }
  .muteUnmuteIcon img {
    left: 17px;
  }
  .greenLogo img {
    width: 50%;
    bottom: 70px !important;
    left: 120px !important;
  }
  .mobile-logo img {
    width: 100px;
    margin-top: 20px;
  }
  .mobile-logo {
    display: block;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* Header */
  .menuIcon {
    width: 20px;
  }
  .main-headerLeft {
    padding: 0% 0% 0% 25px !important;
  }
  img.logo {
    width: 20%;
    margin-top: -19px;
    margin-left: 135px;
    margin-bottom: -19px;
  }
  div.searchForm {
    width: 112px;
    right: 70px !important;
    margin-top: -50px;
  }
  div.searchForm input {
    width: 90px;
    font-size: 10px;
    color: #ff8d6b;
    border-bottom: #ff8d6b solid 1px;
    float: right;
    margin-right: 12px;
  }
  img.cart-icon {
    width: 22px;
    margin-left: 10px;
    float: right;
    margin-bottom: 0;
  }
  .Search_result-content {
    top: 40px;
    width: 130%;
    left: 0;
  }
  .overlay a {
    font-size: 10px !important;
  }
  .overlay .menuitem a {
    border-bottom: #ff8d6b solid 1px;
  }
  /* footer */
  .main-footer.row .footer-Logo {
    width: 60px;
    margin-left: 20px;
  }
  .footer-centerPart p {
    font-size: 11px;
    margin-left: -20px;
    margin-top: 15px;
    border: #96694c solid 1px;
  }
  .col-footer-1 {
    margin-left: 50px;
  }
  .copyright-text p {
    font-size: 7px;
    margin-left: -40px;
  }
  .social-icons img {
    width: 14px;
  }
  .social-icons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  /* home page */
  .home-page .sub-heading {
    font-size: 1rem;
    top: 68%;
    left: -47%;
  }
  .home-page .right {
    order: 0;
  }
  .home-page .left {
    order: 1;
    transform: scaleX(-1);
    background-size: auto;
  }
  .home-page {
    min-height: 768px !important;
  }
  /* sideBarMenu */
  .leftMobileMenu,
  .rightMobileMenu {
    visibility: hidden;
    transition: visibility 1s, opacity 0.1s linear;
    opacity: 0;
    position: relative;
    height: 100%;
  }
  .leftMobileMenu .menu.left,
  .rightMobileMenu .menu.right {
    background: #0b4c45;
  }
  ul.menu.left,
  ul.menu.right {
    top: 0px !important;
  }
  .menu.left {
    padding: 2% 0% 5% 0%;
  }
  ul.menu.left,
  ul.menu.right {
    margin-top: 0px;
  }
  li.menu_i {
    border-bottom: solid 1px #96694c;
    width: 100px;
  }
  li.menu_i:last-child {
    border-bottom: none;
  }
  .menu_i_tx,
  .right .menu_i_tx {
    font-size: 3rem;
  }
  .nav-link {
    padding: 0.5rem 1rem 0.5rem 2rem;
  }
  .menu.right {
    padding: 2% 0% 25% 0%;
  }
  .right .menu_i_tx {
    transform: translate(0, 0) rotate(90deg) !important;
    margin-left: 65px;
    -webkit-text-stroke-color: #e1523e !important;
  }
  #page-heights {
    overflow-y: auto;
    height: 200px;
  }
  /* Why Page */
  p#page-heights {
    text-align: left;
    /* font-size: 15px; */
    margin-top: 0;
    /* line-height: 18px; */
  }
  .whypage-center-text {
    padding: 0% 7% 7%;
  }
  .excerpt {
    margin-top: 50px;
    text-align: justify;
  }
  .whypage-sub-heading {
    color: #808080;
    font-family: "Fira Sans";
    font-size: 11px;
    line-height: 20px;
  }
  .leftSide-center-text p,
  .wp-block-heading {
    color: #00000099;
  }
  .top-border {
    border-bottom: none;
    border-top: none;
  }
  .plus-icon {
    float: right;
    margin-top: -3%;
    cursor: pointer;
    margin-bottom: 20px;
  }
  h1.whypage-heading {
    font-weight: 500;
  }
  .content-pages-heading {
    position: relative;
  }
  .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 70px;
    left: 0;
  }
  /* process page */
  .process-right {
    height: 360px;
  }
  /* .process-right .PageTitleMobile {
    display: block;
    color: #96694c;
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    transform: rotate(-90deg);
    top: 198px;
    left: -38px;
  } */
  /* Scarves Page */
  .product-title-on-Img {
    font-size: 15px !important;
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }
  .discover-text {
    display: none;
  }
  .scrves-page-mobile-1 {
    width: 0% !important;
  }
  .scrves-page-mobile-2 {
    width: 100% !important;
  }
  .scarves-page .product-img {
    height: 270px !important;
  }
  .scarves-page .shop-now {
    border: #96694c solid 1px;
    padding: 5px 15px;
  }
  .scarves-page .shop-now:hover {
    color: #e1523e;
    background: #0b4c45;
    border: none;
  }
  .scarves-page .center-page {
    height: 100% !important;
    min-height: 768px !important;
  }
  .scarves-page .shop-now {
    font-size: 13px;
  }
  .PageTitleMobileScarves {
    display: block;
    position: absolute;
    color: #e1523e;
    right: 0;
    font-weight: 600;
    font-size: 40px;
    transform: rotate(90deg);
    top: 14%;
  }
  /* scarves Detail Page */
  div#myCarousel {
    margin-top: 20px;
  }
  .item {
    padding: 0 10px 0 20px !important;
  }
  .slider-text {
    margin-top: 0px;
  }
  .carousel-indicators {
    margin-bottom: -2rem;
  }
  .carousel-indicators li {
    margin: 20px 15px 0px 0px;
  }
  .buy-text-after-attribute {
    display: none;
  }
  .product-price-slider {
    margin-top: 80px;
    font-size: 15px;
  }
  .buy-text-after-slider a {
    font-size: 15px;
  }
  a.right.carousel-control {
    margin-top: 160px;
    margin-right: -23px;
  }
  a.left.carousel-control {
    margin-top: 160px;
    margin-left: -12px;
  }
  .carousel-control {
    opacity: 1;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .buy-text-after-slider {
    margin-right: 10px;
  }
  /* My Account Page */
  .react-tabs {
    width: 100%;
    border: none;
    margin-top: 5px;
  }
  .react-tabs__tab--selected {
    padding-left: 3px;
    font-size: 12px;
  }
  .react-tabs__tab {
    font-size: 12px;
  }
  button.btn.btn-danger.view-myacc-bnt {
    width: 100px !important;
    font-size: 10px;
    padding-bottom: 1px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .Billing-address-box .thank-you-page {
    font-size: 12px;
  }
  fieldset.myaccount-fieldset {
    padding: 0 20px;
    margin-top: 5px;
  }
  button.btn.btn-danger.view-myacc-bnt.updateBtn {
    width: 100% !important;
  }
  .order-table {
    font-size: 12px;
  }
  .home-page {
    height: 100%;
  }
  .home-page {
    min-height: 950px;
  }
  .myaccount-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .sub-mobile-heading {
    font-size: 6vw;
    font-weight: bold;
    font-style: italic;
    position: relative;
    display: flex;
    color: #df8466;
    justify-content: center;
    font-family: "Adobe Caslon Pro";
    top: 83%;
  }
  .sub-mobile-heading:hover {
    color: #df8466;
  }
  .product-title-details-page {
    padding: 0 0 0 11%;
    font-size: 7vw;
  }
  .shopping-cart {
    width: 385px !important;
  }
  .cart-items .item .image img {
    margin-top: 10px;
    margin-left: -10px;
    width: 43px;
  }
  .cart-items .item .image {
    width: 43px;
    height: fit-content;
  }
  .cart-items .item .description {
    width: 55px;
    margin-top: 18px;
  }
  .cart-items .description span {
    font-size: 11px;
  }
  .cart-items .quantity {
    width: fit-content;
    margin-left: 50px;
  }
  .cart-items .quantity input {
    width: 30px;
    font-size: 12px;
  }
  .total-price {
    font-size: 11px;
    margin-left: 25px;
  }
  button[class*="btn"] {
    width: 25px;
    height: 25px;
  }
  .plus-btn img {
    margin-top: -2px;
  }
  a.checkout-btn {
    font-size: 11px;
  }
  .cart-items {
    /* overflow: hidden; */
  }
  .sub-total h4 {
    color: #e1523e;
    font-size: 13px;
  }
  .sub-total {
    width: 320px;
  }
  .contiune-shoping div {
    margin-right: 0px !important;
  }
  .checkout-page .left-side input.form-control,
  .checkout-page .left-side select.form-control {
    height: 50px;
    margin-top: 15px;
  }
  #msform input,
  #msform textarea,
  #msform select {
    font-size: 10px !important;
  }
  button.checkout-btns {
    width: fit-content;
  }
  .checkout-page .left-side {
    padding: 2rem 0rem 2rem 0.3rem !important;
  }
  button.checkout-btns {
    padding: 11px 40px 22px 40px !important;
  }
  .StripeElement {
    margin-right: 5px !important;
  }
  .auth-page img.logo {
    margin-left: 0px;
  }
  #page-height-exerpt {
    font-size: 15px !important;
  }
  .home-page {
    min-height: 950px;
  }
  /* play page */
  .play-page .product-img {
    width: 75%;
    height: 600px;
    object-fit: contain;
    margin-top: -60px;
  }
  .play-page .heading {
    font-size: 2rem;
    /* text-align: center; */
    padding-left: 10px;
  }
  h3.attribute-heading,
  .play-page .heading,
  .price span,
  .play-name-title,
  .add-to-cart-area {
    margin-left: 0px;
  }
  .play-page .price {
    /* text-align: center; */
    font: 1rem;
    padding-left: 10px;
  }
  .play-page .right-side {
    margin-top: 5%;
    padding-left: 10px;
  }
  .attribute-main {
    margin-left: 0px;
  }
  #scarfs .etbmwA {
    display: block !important;
  }
  .arrow-right {
    transform: rotate(180deg);
    float: right;
    position: absolute;
    top: 63px;
    right: 5px;
    cursor: pointer;
    width: 12px;
  }
  .gqzvZt {
    grid-template-columns: repeat(5, 22%) !important;
    grid-template-rows: 1fr !important;
  }
  .attribute-main .image {
    width: 54px;
  }
  h3.attribute-heading {
    font-size: 0.7rem;
    font-weight: 400;
  }
  h3.attribute-heading {
    margin-top: 10px;
  }
  .play-page .right-side {
    margin-top: 0;
    padding-left: 10px;
  }
  button.add-to-cart-btn {
    width: fit-content;
    height: fit-content;
  }
  .play-name-title input,
  .play-email input,
  .play-page .input-felid {
    width: 98%;
    height: 45px;
    font-size: 10px;
  }
  .price span {
    font-size: 16px;
    position: relative;
    left: 1px;
    color: #e1523e;
  }
  /* .slide- {
    height: 163vh;
} */
  .main-footer {
    height: 6rem !important;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
    padding-bottom: 5.99rem;
  }
  .playpage-heading {
    color: #96694c;
    font-weight: 900;
    width: -moz-fit-content;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin-left: 5%;
  }
  .text-on-image {
    font-size: 1.6vw;
    line-height: 15px;
  }
  .play-page .product-play-img {
    margin: 0 10px;
  }
  .checkout-guest {
    text-align: left;
    position: absolute;
    left: 51%;
    top: 0;
  }
  #msform fieldset {
    padding: 0;
  }
  .shipping-top-heading {
    padding-left: 0px;
  }
  #progressbar {
    padding-left: 0 !important;
  }
  .checkout-page .main-heading {
    font-size: 1.7rem;
    left: -6%;
    top: -30px;
  }
  .checkout-page .progressbar-items {
    font-size: 0.5rem;
  }
  #progressbar li:before {
    margin-top: 4px;
    width: 17px;
    height: 17px;
  }
  .slider-text p {
    padding: 0 6px;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  img.logo {
    width: 14%;
    margin-top: -19px;
    margin-left: 185px;
    margin-bottom: -19px;
  }
  .menu.right {
    padding: 2% 0% 14% 0%;
  }
  .slider-text p {
    padding: 0 6px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .scarves-page {
    /* height: 90vh; */
    background-color: #0b4c45;
}
  .landing-page-icons {
    left: 5px !important;
  }

  .carousel-indicators li span{
    width: 8px;
    height: 8px;
  }
  .carousel-indicators .active span{
    width: 13px;
    height: 13px;
  
    background-color: #96694c;
  }
 
  .slider-content-height {
    height: 318px;
    overflow-y: auto;
}
  .carousel-indicators {
    justify-content: space-evenly;
  }
  .play-page .product-play-img {
    width: 350px;
}
.cart-items .item .image img {
 
  height:43px;
  object-fit: cover;
}
.content-bottom-logo .img-bottom-logo{
  display: block;
}
}
