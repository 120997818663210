 .my-account-page{
  min-height: 768px;
 }

.react-tabs {
  display: flex;
  margin-left: 36px;
  height: 700px;
  width: 70%;
  border:1px solid #0b4c4526;
  color: white;
  margin-left: auto;
  flex-direction: column;
  margin-top: 100px;
  /* background: #1c1d1f; */
  margin-right: auto;
}
table{
  width: 100% !important;
}
.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  background: #976a4d;
}

.react-tabs__tab {
  height: 50px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #fff;
  border-top: #976a4d 4px solid;
}

.react-tabs__tab--selected {
  background: #0b4c45;
  border-color: #0b4c45;
  border-top: 4px solid #ff8d6b;
  color: white;
 
}

.react-tabs__tab-panel,.order-panel {
  display: none;
  width: 100%;
}
.panel-content h2 {
  padding: 10px 0;
}
.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding: 11px 22px !important;
}

.react-tabs__tab--selected:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.react-tabs__tab--selected {
  padding: 11px 22px !important;
}

.panel-content {
  text-align: center;
  color: #0b4c45;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
button.btn.btn-info {
  width: fit-content;
}
.order-panel {
  overflow: auto;
  height: 600px;
  width: 100%;
}
.view-myacc-bnt{
  width: 100%;
}
button.btn.btn-danger.view-myacc-bnt {
  background: #0b4c45;
  border: #0b4c45 solid 1px;
  padding-bottom: 29px;
}
button.btn.btn-danger.view-myacc-bnt:hover {
  background: transparent;
  border: #0b4c45 solid 1px;
  color: #0b4c45;

}

input.btn.btn-danger.view-myacc-bnt {
  background: #0b4c45;
  border: none;
}
input.btn.btn-danger.view-myacc-bnt:hover {
  background: transparent;
  border: #0b4c45 solid 1px;
  color: #0b4c45;
}
.myaccount-fieldset input{
  color: #0b4c45 !important;
}
.myaccount-loader .spinner {
  left: 50% !important;
  position: absolute;
  top: 50%;
}